import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import { getRegionSelectOptions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes}  from "./CountryCodes"
import DropzoneComponent from "./DropzoneComponent"
import ProfilePicture from "./ProfilePicture"
import QuestionPicture from "./QuestionPicture"
import ActivityMoreInfo from "./ActivityMoreInfo"
import RegistrationReviewForm from "./RegistrationReviewForm"
import { format } from 'date-fns'
import {
  getShoeSizeOptionsObject,
  getJacketShirtSizeOptions,
  nonAlcoholicBeverageOptions,
  alcoholicBeverageOptions,
  mixerBeverageOptions,
  snacksOptions,
  foodRestrictionOptions,
  getArrivalDateOptions,
  getRoomPreferenceOptions,
  getDepartureDateOptions,
  getNumberOfGuestOptions,
  getShoeSizeOptions
} from "@utility/RegistrationOptions"


class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      emailTaken: false,
      questionImage: null,
      submitting: false,
    }

    this.formURL = this.formURL.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.checkEmailTaken = this.checkEmailTaken.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  renderTester() {
    const { participant, hide_test } = this.props;
    // const { regPages } = this.state;
    // if(participant && participant.test_flag && (participant.email||"").indexOf("@jaguardesignstudio") != -1 && !hide_test) {
    const pages = [0,1,2].map((page, index) => {
      return {id: index}
    })

    return (
      <div className="sg-reg-page-swapper">
        {pages.map(x=>(
          <div onClick={()=>this.setState({
            page: x.id + 1
          })}className="sg-reg-page-swapper-item" key={`swap-id-${x.id}`}>
            Go To Page {x.id + 1}
          </div>
        ))}
      </div>
    )
    // }
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isEmailPersonal(email) {
    const personalEmails = [
      "@sumologic",
      "@jaguardesignstudio",
    ];
    if(email &&  this.includesPolyfill(email.toLowerCase(), ".") && this.includesPolyfill(email.toLowerCase(), "@") && !personalEmails.filter(personalEmail => (this.includesPolyfill(email.toLowerCase(),personalEmail))).length > 0) {
      return true;
    }
    return false;
  }

  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  isPageTwoValid(formikProps) {
    const { values } = formikProps;
    let fieldList = [
      "jacketshirtsizeself",
      "shoesizeself",
    ]

    if(this.isBringingGuest(formikProps)) {
      fieldList = fieldList.concat([
        "jacketshirtsizeguest",
        "shoesizeguest",
      ])
    }

    if(this.checkActivityErrors(formikProps)) {
      return false;
    }

    if(values.user.chosenactivites) {
      if(values.user.chosenactivites >= 1){
        fieldList.concat([
          "selectedactivitypersoncount1"
        ])
      }
      if(values.user.chosenactivites >= 2){
        fieldList.concat([
          "selectedactivitypersoncount2"
        ])
      }
    }

    let arrayFields = [
      "foodrestrictions",
    ]


    if(this.isBringingGuest(formikProps)) {
      // if (values.user.selectedactivitypersoncount1 !== 'Both' && values.user.selectedactivitypersoncount2 !== 'Both') {
      //   if (values.user.selectedactivitypersoncount1 === "" || values.user.selectedactivitypersoncount2 === "") {
      //     return false;
      //   }
      // }
      arrayFields = arrayFields.concat([
        "guestfoodrestrictions",
      ])
    }

    const validFields = fieldList.filter(field =>
       values["user"][field] != null
       &&
       values["user"][field] != undefined
       &&
       (
         typeof values["user"][field] === 'string' ? values["user"][field].length > 0 : true
       )
     );

    if (validFields.length != fieldList.length) {
      return false;
    }

    const validArrayFields = arrayFields.filter(field=>
      field && field.length > 0
    )
    if(validArrayFields.length != arrayFields.length) {
      return false;
    }
    return true;
  }

  isPageOneValid(formikProps) {
    const { values } = formikProps;
    // "birthdate",
    // "guestbirthdate",
    // "postal_code",
    // "guestpostalcode",

    let fieldList = [
      "name_first",
      "name_last",
      "cellphonenumber",
      "email",
      "override_work_address_street_1",
      "override_work_address_city",
      "override_work_address_state",
      "override_work_address_postcode",
      "ecname",
      "ecnumber",
      "arrivaldate",
      "departuredate",
      "roompreference",

      "airlineinbound",
      "flightnumberinbound",
      "arrivaldateinbound",
      "arrivaltimeinbound",
      "airlineoutbound",
      "flightnumberoutbound",
      "departuredateoutbound",
      "departuretimeoutbound",

      "hobby",
      "song",
      "quote"
    ]

    if(values['user']['bringingguest'] == null) {
      return false;
    }
    if(values['user']['picture_1'] == '' && values['user']['picture_2'] == '' && values['user']['picture_3'] == '') {
      return false;
    }

    if(values['user']['usaddress'] == false) {
      fieldList = fieldList.concat([
        "override_work_address_country",
        "passportissued",
        "passportnumber",
        "passportexpiration",
        "passportcountry"
      ])
    }
    if(values['user']['bringingguest'] == true) {
      fieldList = fieldList.concat([
        "guestfirstname",
        "guestlastname",
        "guestcellphonenumber",
        "guestemailaddress",
      ])
      if(values['user']['guestaddressdifferent'] == true) {
        fieldList = fieldList.concat([
          "guestaddress1",
          "guestaddresscity",
          "guestaddressstate",
          "guestemergencycontactname",
          "guestemergencycontactnumber",
        ])
      }
      if(values['user']['flightinfodifferent'] == true) {
        fieldList = fieldList.concat([
          "airlineinboundguest",
          "flightnumberinboundguest",
          "arrivaldateinboundguest",
          "arrivaltimeinboundguest",
          "airlineoutboundguest",
          "flightnumberoutboundguest",
          "departuredateoutboundguest",
          "departuretimeoutboundguest",
        ])
      }
    }

    const validFields = fieldList.filter(field =>
      values["user"][field] != null
      &&
      values["user"][field] != undefined
      &&
      (
        typeof values["user"][field] === 'string' ? values["user"][field].length > 0 : true
      )
    );

    const isEmailValid = !this.isEmailPersonal(values["user"]["email"]);
    if (!(validFields.length == fieldList.length && isEmailValid)) {
      return false;
    }
    return true;
  }


  checkEmailTaken(formikProps){
    this.setState({
      emailTaken: false,
    });

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();
    form_data.append("email", formikProps.values.user['email']);


    fetch('/registration/check_email_taken', {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        if(json.taken){
          this.setState({
            emailTaken: true,
          })
        }else {
          this.setState({
            emailTaken: false,
          })
        }
      }
    });

  }

  renderPageOne(formikProps) {
    const { values } = formikProps;
    const { pageOneError, emailTaken, checkError, pageThreeError, fullPageError } = this.state;
    // <div className="col-xs-12 col-md-6">
    //   <label className="required"> Birthdate </label>
    //   {this.renderField('birthdate',formikProps, false, {type:"date"})}
    // </div>
    return (
      <>
      <div className={`registration-page-one ${this.pageHiddenClass(1)}`}>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="sg-pc-section-large-body sg-presidents-club-reg-text-section">
              The modern embodiment of the spirit and soul of the Caribbean, this beachfront oasis is everything you could need in a luxury getaway. A place where smiles greet you as you step off the boat, where the crest of the sun over Nevis Peak sparkles on the waters of two oceans that meet but never cross, Nevis is a captivating combination of adventure, serenity and recreation that waits for your discovery.
              <br />
              <br />
              For detailed information on the program, please visit the <a href="https://drive.google.com/drive/folders/1OyQwN6cIvqVi2UbO6zTpniM-mygfyH47?usp=sharing" target="_blank">Information kit</a>.
              <div className="sg-pc-section-large-body-info-box">
                Prior to completing this form, you must book flights for yourself and your guest in TripActions and have your flight information details ready during the registration process.
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            Please fill in your contact information.
          </div>
          <div className="col-xs-12 col-md-5">

            <label className="required"> First name </label>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-md-5">
            <label className="required"> Last name </label>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-md-2">
            <label className=""> MI </label>
            {this.renderField('middleinitial',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="" style={{fontFamily: "Grotesque"}}> Please ensure name aligns with your driver’s license (US) or passport (Intl.)</label>
          </div>

          <div className="col-xs-12 col-md-6">
            <label className="required"> Cell phone number </label>
            {this.renderField('cellphonenumber',formikProps)}
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="required"> Email </label>
            {this.renderField('email',formikProps, this.isEmailPersonal(formikProps.values.user.email) || emailTaken,{onKeyUp: this.checkEmailTaken})}
            {this.isEmailPersonal(formikProps.values.user.email) ? (
              <div className="sg-cnt-show-error">
                 Thank you for your interest in this event.  A sumologic address is required to attend.  If you would like to request an exception, please email <a href="mailto:events@sumologic.com">events@sumologic.com</a> who will contact you to request additional information.
              </div>
              ) : ""
            }
            {emailTaken ? (
              <div className="sg-cnt-show-error">
                 This email has already been taken.
              </div>
            ): ""}
          </div>

        </div>
        <div className="sg-presidents-club-reg-text-section" />
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            Please provide your address.
          </div>
          <div className="col-xs-12 col-sm-6">
            {this.renderCheckbox('usaddress', true, formikProps)}
            <label className="checkbox-label">US address</label>
          </div>
          <div className="col-xs-12 col-sm-6">
            {this.renderCheckbox('usaddress', false, formikProps)}
            <label className="checkbox-label">International address</label>
          </div>
          {this.renderAddressFields(formikProps)}
        </div>
        <div className="sg-presidents-club-reg-text-section" />
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            Please provide your flight information.
          </div>
          {this.renderFlightInfoFields(formikProps)}
        </div>
        <div className="sg-presidents-club-reg-text-section" />
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            Bringing a guest? <span style={{color:"red"}}>*</span>
          </div>
          <div className="col-xs-12 col-sm-6">
            {this.renderCheckbox('bringingguest', true, formikProps)}
            <label className="checkbox-label">Yes, I will bring a guest.</label>
          </div>
          <div className="col-xs-12 col-sm-6">
            {this.renderCheckbox('bringingguest', false, formikProps)}
            <label className="checkbox-label">No, I will not bring a guest.</label>
          </div>
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {checkError && values.user.bringingguest == null ? "Please mark whether you are bringing a guest" : ""}
            </div>
          </div>
          {this.renderBringingGuest(formikProps)}
        </div>
        <div className="sg-presidents-club-reg-text-section" />
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            About your stay at the beautiful Four Seasons Nevis
            <br/>
            <br/>
          </div>
          <div className="col-xs-12 sg-pc-section-body">
            The hotel will honor our negotiated rate of $535 per night, plus taxes and fees, for three days prior and/or three days following our incentive trip dates, subject to availability. We've secured a limited number of pre-and post-dates for you to reserve on a first-come, first-served basis. Sumo Logic will cover your hotel accommodations from May 13 (check-in) through May 18 (check-out). Any additional nights beyond this period will be at your own expense.
            <br />
            <br />
            It's important to note that we're providing an extra night on May 13 as a travel recovery day, considering the long flights. You have the option to use May 13 at another hotel during your travels. If you opt for this, you're responsible for booking and paying for the alternate accommodation. You can then seek reimbursement via Expensify, up to $375 inclusive. However, please be aware that choosing this option means forfeiting the additional night at the Four Seasons on May 13.
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="required"> Check-in date </label>
            {this.renderSelectField('arrivaldate',formikProps, getArrivalDateOptions())}
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="required"> Check-out date </label>
            {this.renderSelectField('departuredate',formikProps, getDepartureDateOptions())}
          </div>
          <div className="col-xs-12 col-md-6">
            <label className=""> Room preference <span style={{color: "red"}}>*</span> (not guaranteed) </label>
            {this.renderSelectField('roompreference',formikProps, getRoomPreferenceOptions())}
          </div>
        </div>
        <div className="sg-presidents-club-reg-text-section" />
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            Please tell us a little about yourself.
            <br/>
          </div>
          <div className="col-xs-12">
            <label className="required"> What is your favorite hobby? </label>
            {this.renderField('hobby',formikProps)}
          </div>
          <div className="col-xs-12">
            <label className="required"> What song gets you motivated to conquer the day? </label>
            {this.renderField('song',formikProps)}
          </div>
          <div className="col-xs-12">
            <label className="required"> What is your favorite motivational/inspirational quote? </label>
            {this.renderField('quote',formikProps)}
          </div>
        </div>
        <div className="sg-presidents-club-reg-text-section" />

        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">
            Please share three fun photos capturing moments with your family, friends, pets, or any cherished memories.
            <br/>
            <br/>
          </div>
          <div className="col-xs-12 sg-pc-section-body">
            Your photos may be utilized for something intriguing in the near future! Thank you in advance!
          </div>
          <div className="col-xs-12 sg-pc-section-body" style={{fontSize: "12px"}}>
            Recommended image size limit: 2MB
          </div>
          <div className="col-xs-12">
            <div className="reg-picture-wrapper">
              <ProfilePicture formikProps={formikProps} fieldValue={'picture_1'} erroring={checkError && values.user.picture_1 == "" && checkError && values.user.picture_2 == "" && checkError && values.user.picture_3 == ""} />
              <ProfilePicture formikProps={formikProps} fieldValue={'picture_2'} />
              <ProfilePicture formikProps={formikProps} fieldValue={'picture_3'} />
            </div>
          </div>
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {
                checkError && values.user.picture_1 == "" && checkError && values.user.picture_2 == "" && checkError && values.user.picture_3 == "" ?
                "Please upload at least one picture" :
                ""
              }
            </div>
          </div>
        </div>
        <div className="sg-presidents-club-reg-text-section" />

        <div className="row">
          <div className="col-xs-12 sg-pc-section-header" style={{color: '#21B9CC'}}>
            <br/>
            <br/>
            Looking good? Let’s move on to the fun stuff.
          </div>
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {checkError && !this.isPageOneValid(formikProps) ? pageOneError : ""}
            </div>
            <br/>
            <br/>
            <button onClick={()=>this.nextPage(formikProps)} type="button" className="sg-registration-button">
              Continue
            </button>
          </div>
        </div>
      </div>
      </>
    )
  }

  renderFlightInfoFields(formikProps) {
    return (
      <>
        <div className="col-xs-12 sg-pc-section-body" style={{marginTop: "30px"}}>
          Inbound
        </div>
        <div className="col-xs-12">
          <label className="" style={{fontFamily: "Grotesque", marginTop: "0px"}}> Enter last leg of your inbound flight only, if multiple flights.</label>
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Airline </label>
          {this.renderField('airlineinbound',formikProps)}
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Flight number </label>
          {this.renderField('flightnumberinbound',formikProps)}
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Arrival date </label>
          {this.renderField('arrivaldateinbound',formikProps, false, {type: "date", date_min: "2024-05-01", date_max: "2025-01-01"})}
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Arrival time </label>
          {this.renderField('arrivaltimeinbound',formikProps, false, {type: "time"})}
        </div>
        <div className="col-xs-12 sg-pc-section-body" style={{marginTop: "30px"}}>
          Outbound
        </div>
        <div className="col-xs-12">
          <label className="" style={{fontFamily: "Grotesque", marginTop: "0px"}}> Enter first leg of your outbound flight only, if multiple flights.</label>
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Airline </label>
          {this.renderField('airlineoutbound',formikProps)}
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Flight number </label>
          {this.renderField('flightnumberoutbound',formikProps)}
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Departure date </label>
          {this.renderField('departuredateoutbound',formikProps, false, {type: "date", date_min: "2024-05-01", date_max: "2025-01-01"})}
        </div>
        <div className="col-xs-12 col-md-6">
          <label className="required">Departure time </label>
          {this.renderField('departuretimeoutbound',formikProps, false, {type: "time"})}
        </div>
      </>
    );
  }

  renderGuestFlightInfoFields(formikProps) {
    return (
      <>
        <div className="col-xs-12 sg-pc-section-body" style={{marginTop: "30px"}}>
          Inbound
        </div>
        <div className="col-xs-12">
          <label className="" style={{fontFamily: "Grotesque", marginTop: "0px"}}> Enter last leg of your inbound flight only, if multiple flights.</label>
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Airline </label>
          {this.renderField('airlineinboundguest',formikProps)}
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Flight number </label>
          {this.renderField('flightnumberinboundguest',formikProps)}
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Arrival date </label>
          {this.renderField('arrivaldateinboundguest',formikProps, false, {type: "date", date_min: "2024-05-01", date_max: "2025-01-01"})}
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Arrival time </label>
          {this.renderField('arrivaltimeinboundguest',formikProps, false, {type: "time"})}
        </div>
        <div className="col-xs-12 sg-pc-section-body" style={{marginTop: "30px"}}>
          Outbound
        </div>
        <div className="col-xs-12">
          <label className="" style={{fontFamily: "Grotesque", marginTop: "0px"}}> Enter first leg of your outbound flight only, if multiple flights.</label>
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Airline </label>
          {this.renderField('airlineoutboundguest',formikProps)}
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Flight number </label>
          {this.renderField('flightnumberoutboundguest',formikProps)}
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Departure date </label>
          {this.renderField('departuredateoutboundguest',formikProps, false, {type: "date", date_min: "2024-05-01", date_max: "2025-01-01"})}
        </div>
        <div className="col-xs-12 col-md-5">
          <label className="required">Departure time </label>
          {this.renderField('departuretimeoutboundguest',formikProps, false, {type: "time"})}
        </div>
      </>
    );
  }


  getLoremIpsum() {
    return "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.";
  }

  renderActivityCheckbox(activity, formikProps,customValidation = false) {
    const { user } = formikProps.values;
    const  { chosenactivities } = user;

    const { checkError } = this.state;

    let erroring = checkError && (customValidation);
    const checked = chosenactivities.indexOf(activity.answers.id) != -1

    if (activity.answers.full) {
      return <div style={{width: "25px", display: "inline-flex"}}></div>
    }

    return (
      <div
        className={`sg-registration-checkbox sg-registration-checkbox-${checked ? "checked" : "unchecked"} ${erroring ? 'field-erroring': "" }`}
        onClick={() => {
          const { user } = formikProps.values;
          if(checked) {
            //Unclick
            user['chosenactivities'] = user['chosenactivities'].filter(x=>x != activity.answers.id)
          }else {
            if(this.isBringingGuest(formikProps)) {
              if(user['chosenactivities'].length >= 1 && user['selectedactivitypersoncount1'] === "Both") {
                this.setState({
                  activityError: "You cannot select more than one activity per person."
                })
              } else if(user['chosenactivities'].length >= 2) {
                this.setState({
                  activityError: "You cannot select more than two activities."
                })
              }else {
                user['chosenactivities'] = user['chosenactivities'].concat([activity.answers.id])
                this.setState({
                  activityError: null
                })
              }
            } else {
              user['chosenactivities'] = [activity.answers.id]
              this.setState({
                activityError: null
              })
            }
          }
          formikProps.setFieldValue('user', user)
        }}
      >
          <img style={{visibility: checked ? "visible" : "hidden"}} src="/layout/checked.svg"/>
      </div>
    )
  }

  renderActivities(formikProps, prefix="") {
    const { cms_section } = this.props;
    const { values } = formikProps;
    return cms_section.items.filter((item) =>
      item.slug !== 'atv' &&
      item.slug !== 'deep-sea-fishing'
    ).sort((a, b) => a.answers.sort_index - b.answers.sort_index).map(activity => (
      <div className="col-xs-12 sg-pc-activity" key={`activity-${activity.id}`}>
        {
          activity.answers.top_description ?
          <div className="sg-pc-section-activity-header" style={{fontFamily: "Grotesque Medium", fontSize: "16px"}} dangerouslySetInnerHTML={{__html: activity.answers.top_description}} />
          :
          <></>
        }
        <div className="sg-pc-section-activity-sub-header">
          {activity.descriptionOnly ? <div className="sg-pc-section-activity-placeholder-checkbox"/> : this.renderActivityCheckbox(activity, formikProps)}
          <label className="checkbox-label sg-pc-section-activity-sub-header" dangerouslySetInnerHTML={{__html: activity.answers.name}} />
          {activity.answers.full ? <label style={{color: "#EF4723", fontSize: "16px"}}>&nbsp;FULL</label> : ''}
          <div className="sg-pc-section-activity-description-container">
            <div className="sg-pc-section-activity-time">
              {activity.answers.date}
            </div>
            {this.renderActivityFields(formikProps, activity, prefix)}

            <div className="sg-pc-section-activity-description">
              {/* <div>{activity.answers.id}</div> */}
              {
                activity.answers.selecteddescription !== "" && values.user["chosenactivities"].includes(activity.answers.id) ?
                <div dangerouslySetInnerHTML={{__html: activity.answers.selecteddescription}} />
                :
                <></>
              }
            </div>
            <div className="sg-pc-section-activity-description" dangerouslySetInnerHTML={{__html: activity.answers.description}} />
            {
              activity.answers.more_info === undefined || activity.answers.more_info === "" ?
              <></>
              :
              <ActivityMoreInfo activity={activity} />
            }
          </div>
        </div>
      </div>
    ));

    // return this.getActivities(formikProps).map(activity=>(
    //   <div className="col-xs-12 sg-pc-activity">
    //     {
    //       activity.top_description ?
    //       <div className="sg-pc-section-activity-header" style={{fontFamily: "Grotesque Medium", fontSize: "16px"}}>{activity.top_description}</div>
    //       :
    //       <></>
    //     }
    //     <div className="sg-pc-section-activity-sub-header">
    //       {activity.descriptionOnly ? <div className="sg-pc-section-activity-placeholder-checkbox"/> : this.renderActivityCheckbox(activity, formikProps)}
    //       <label className="checkbox-label sg-pc-section-activity-sub-header ">{activity.name}</label>
    //       {activity.full ? <label style={{color: "#EF4723", fontSize: "16px"}}>&nbsp;FULL</label> : ''}
    //       <div className="sg-pc-section-activity-description-container">
    //         <div className="sg-pc-section-activity-time">
    //           {activity.date}
    //         </div>
    //         {this.renderActivityFields(formikProps, activity, prefix)}

    //         <div className="sg-pc-section-activity-description">
    //           {
    //             activity.selectedDescription !== undefined && values.user["chosenactivities"].includes(activity.id) ?
    //             <>{activity.selectedDescription}</>
    //             :
    //             <></>
    //           }
    //           {activity.description || this.getLoremIpsum()}
    //           {
    //             activity.more_info === undefined ?
    //             <></>
    //             :
    //             <ActivityMoreInfo activity={activity} />
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // ))
  }

  isBringingGuest(formikProps) {
    const { values } = formikProps;
    return !!values.user.bringingguest;
  }

  activityPeopleOptions(formikProps) {
    const { values } = formikProps;
    if(this.isBringingGuest(formikProps)) {
      return ["Self","Guest","Both"].map(x=>{return{value: x, label: x}})
    }
    return ["Self"].map(x=>{return{value: x, label: x}})
  }

  renderActivityFields(formikProps, activity) {
    const { values } = formikProps;
    const fieldIndex = values.user[`chosenactivities`].indexOf(activity.answers.id)
    if (fieldIndex != -1) {
      return (
        <div className={`row individual-activity-field ${this.isBringingGuest(formikProps) ? "" : "individual-activity-field-no-padding"}`}>
          {this.isBringingGuest(formikProps) ? (
            <div className="col-xs-12 col-sm-6 col-md-3">
              <label className="" > People attending </label>
              {this.renderSelectField(`selectedactivitypersoncount${fieldIndex+1}`, formikProps, this.activityPeopleOptions(formikProps))}
            </div>
          ) : ""}

          {
            activity.answers.id === 'golf' ?
            this.getGolfFields(formikProps).filter(x=>!x.dont_show).map(field=>
              this.renderActivityFormField(formikProps,activity,field,"")
            )
            :
            ''
          }

          {/* {activity.fields.filter(x=>!x.dont_show).map(field=>
            this.renderActivityFormField(formikProps,activity,field,"")
          )} */}
        </div>
      );
    }
  }

  renderActivityFormField(formikProps, activity, field, prefix) {
    const { checkError } = this.state;
    switch(field.type) {
      case "yesno":
        return (
          <>
          <div className="col-xs-12">
            <div className="row">
              <div className="col-xs-12">
                <label className="required"> {field.name} </label>
              </div>
              <div className="col-xs-6">
                {this.renderCheckbox(`${prefix}${field.id}`, "Yes", formikProps)}
                <label className="checkbox-label">Yes</label>
              </div>
              <div className="col-xs-6">
                {this.renderCheckbox(`${prefix}${field.id}`, "No", formikProps)}
                <label className="checkbox-label">No</label>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {checkError && formikProps.values.user[field.id] === "" ? 'Please select a response.' : ''}
            </div>
          </div>
          </>
        )
      case "select":
        return(
          <div className="col-xs-12 col-md-6">
            {/* <label className="required" > {field.name} </label> */}
            {this.renderSelectField(`${prefix}${field.id}`, formikProps, field.options.map(x=>{return{value: x, label: x}}))}
          </div>
        );
    }
  }

  showActivityWarning(formikProps) {
    const { values } = formikProps;
    // const { user } = values;
    if (this.isBringingGuest(formikProps)) {
      if (values.user.selectedactivitypersoncount1 !== "" || values.user.selectedactivitypersoncount2 !== "") {
        if (
          (values.user.selectedactivitypersoncount1 == "" || values.user.selectedactivitypersoncount2 == "") &&
          !(values.user.selectedactivitypersoncount1 === "Both" || values.user.selectedactivitypersoncount2 === "Both")
        ) {
          return "You have selected only one individual excursion. Please select another one. You can also continue without selecting a second individual excursion."
        }
      }
    }
    return ""
  }

  renderPageTwo(formikProps) {
    const { checkError, pageTwoError, activityError } = this.state;
    const { values } = formikProps;

    return (
      <div className={`registration-page-two ${this.pageHiddenClass(2)}`}>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="sg-pc-section-large-body sg-presidents-club-reg-text-section">
              <div style={{textAlign: 'left'}}>
                <span>
                  We've curated a diverse array of fun activities for you to enjoy in Nevis and the surrounding areas. To ensure your experience is nothing short of exceptional, please take note of the following:
                </span>
              </div>
              <div className="sg-presidents-club-reg-text-section-list">
                <ul>
                  <li>
                    You and your guest are not required to select the same activity. Feel free to choose what resonates best with each of you.
                  </li>
                  <li>
                    Select one excursion per guest.
                  </li>
                  <li>
                    Activities and their respective timings are subject to potential changes.
                  </li>
                  <li>
                    Departure locations will be communicated to you onsite.
                  </li>
                  <li>
                    Prior to signing up for any activity, we strongly advise thoroughly reviewing the activity descriptions to ensure they align with your preferences and expectations.
                  </li>
                  <li>
                    Some excursions may have minimum participant requirements. If these requirements aren't met, we may need to offer alternative activity options.
                  </li>
                  <li>
                    Lunch will be provided for all activities. Depending on the program, it may be included or provided as an upscale box lunch upon departure to the activity or onsite.
                  </li>
                  <li>
                    Gratuities for hosts, drivers, captains, and other personnel are already included.
                  </li>
                  <li>
                    Changes to activity selections cannot be accommodated after April 10. Should you need to cancel or alter your chosen activity, please ensure to do so before this date.
                  </li>
                  <li>
                    Should you wish to participate in additional activities at your own expense, our onsite hospitality desk will have experts available to assist you.
                  </li>
                  <li>
                    We strongly encourage participation in all scheduled activities as they promise unforgettable experiences.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <br/>
          </div>
          {this.isBringingGuest(formikProps) ? (
            <>
            <div className="col-xs-12 sg-pc-section-header">Please choose one individual outing per person.</div>
            <div className="col-xs-12 sg-pc-section-small-body">You and your companion are welcome to choose different activities if you wish.<br/><br/></div>
            </>
          ) : (
            <>
            <div className="col-xs-12 sg-pc-section-header">Please choose one individual outing.</div>
            </>
          )}

          {this.renderActivities(formikProps)}
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {checkError && this.checkActivityErrors(formikProps) ? activityError : ""}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {this.showActivityWarning(formikProps)}
            </div>
          </div>
        </div>

        <div className="sg-presidents-club-reg-text-section"></div>
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header">Outerwear</div>
          <div className="col-xs-12 col-md-6">
            <label className="" > Jacket/Shirt size <span style={{color: "red"}}>*</span> {this.isBringingGuest(formikProps) ? " - Self":""} </label>
            {this.renderSelectField('jacketshirtsizeself', formikProps, getJacketShirtSizeOptions())}
          </div>
          {this.isBringingGuest(formikProps) ? (
            <div className="col-xs-12 col-md-6">
              <label className=""> Jacket/Shirt size <span style={{color: "red"}}>*</span> - Guest</label>
              {this.renderSelectField('jacketshirtsizeguest', formikProps, getJacketShirtSizeOptions())}
            </div>
          ):""}
          <div className="col-xs-12 col-md-6">
            <label className="" > Shoe size <span style={{color: "red"}}>*</span>{this.isBringingGuest(formikProps) ? " - Self":""} </label>
            {this.renderSelectField('shoesizeself', formikProps, getShoeSizeOptionsObject())}
          </div>
          {this.isBringingGuest(formikProps) ? (
            <div className="col-xs-12 col-md-6">
              <label className=""> Shoe size <span style={{color: "red"}}>*</span> - Guest</label>
              {this.renderSelectField('shoesizeguest', formikProps, getShoeSizeOptionsObject())}
            </div>
          ):""}
        </div>
        <div className="sg-presidents-club-reg-text-section"></div>
        <div className="row" style={{    display: "flex", flexWrap: "wrap"}}>
          <div className="col-xs-12 sg-pc-section-header">Please share with us what you {this.isBringingGuest(formikProps) ? "and your guest ":""}would enjoy.</div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Food restrictions <span style={{color: "red"}}>*</span>{this.isBringingGuest(formikProps) ? " - Self":""} </label>
            {this.renderMultipleSelect('foodrestrictions', formikProps, foodRestrictionOptions(), false, {other: true})}
          </div>
          {this.isBringingGuest(formikProps) ? (
            <div className="col-xs-12 col-sm-6">
              <label className=""> Food restrictions <span style={{color: "red"}}>*</span> - Guest </label>
              {this.renderMultipleSelect('guestfoodrestrictions', formikProps, foodRestrictionOptions(), false, {other: true})}
            </div>
          ):""}
          <div className="col-xs-12">
            <label className=""> Is there anything else you would like to share? Or have any preferences or questions?</label>
            {this.renderField('otherquestions', formikProps, false, {component: 'textarea', not_required: true, styles:{minHeight:"100px"}})}
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="sg-cnt-show-error">
              {checkError && !this.isPageTwoValid(formikProps) ? pageTwoError : ""}
            </div>
            <br/>
            <br/>
            <button onClick={()=>this.nextPage(formikProps)} type="button" className="sg-registration-button">
              Review and finalize
            </button>
            <button onClick={()=>this.prevPage(formikProps)} type="button" className="sg-clear-registration-button">
              Return to previous page
            </button>
          </div>
        </div>
      </div>
    )

  }

  renderPageThree(formikProps) {
    const { checkError, pageThreeError, fullPageError, submitting} = this.state;
    const { cms_section } = this.props;

    return (
      <div className={`registration-page-three ${this.pageHiddenClass(3)}`}>
        <div className="row">
          <div className="col-xs-12 ">
            <div style={{paddingBottom: "40px", marginBottom: 0, borderBottom: 0}} className="sg-pc-section-large-body sg-presidents-club-reg-text-section">
              Please review your selections before submitting.
            </div>
          </div>
          <div className="col-xs-12  sg-pc-registration-review-form">
            <RegistrationReviewForm activities={cms_section.items} formikProps={formikProps} />
            <br/>
            <div className="row">
              <div className="col-xs-12">
                <div className="sg-cnt-show-error">
                  {checkError ? fullPageError : ""}
                  {checkError ? pageThreeError : ""}
                </div>
                <br/>
                <br/>
              </div>
              <div className="col-xs-12">
                {
                  submitting ?
                  <button type="button" className="sg-registration-button" style={{padding: "5px 45px"}}>
                    <div className="lds-ring-submit" style={{verticalAlign: "middle"}}><div></div><div></div><div></div><div></div></div>
                  </button>
                  :
                  <button onClick={()=>this.submitFormButtonOnClick(formikProps)} type="button" className="sg-registration-button">
                    Submit
                  </button>
                }
                <button onClick={()=>this.prevPage(formikProps)} type="button" className="sg-clear-registration-button">
                  Wait, I need to make changes
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderBringingGuest(formikProps) {
    const { values } = formikProps;

    // <div className="col-xs-12 col-md-6">
    //   <label className="required"> Guest Birthdate </label>
    //   {this.renderField('guestbirthdate',formikProps, false, {type:"date"})}
    // </div>

    if(values.user.bringingguest) {

      const guestaddressdifferent = values.user.guestaddressdifferent ? (
        <>
        <div className="col-xs-12 col-sm-6">
          {this.renderCheckbox('guestusaddress', true, formikProps)}
          <label className="checkbox-label">US address</label>
        </div>
        <div className="col-xs-12 col-sm-6">
          {this.renderCheckbox('guestusaddress', false, formikProps)}
          <label className="checkbox-label">International address</label>
        </div>
        {this.renderGuestAddressFields(formikProps)}
        </>
      ):""
      const guestflightinfodifferent = values.user.flightinfodifferent ? (
        <>
          {this.renderGuestFlightInfoFields(formikProps)}
        </>
      ):""

      return (
        <>
          <div className="col-xs-12 col-md-5">
            <label className="required">Guest first name </label>
            {this.renderField('guestfirstname',formikProps)}
          </div>
          <div className="col-xs-12 col-md-5">
            <label className="required"> Guest last name </label>
            {this.renderField('guestlastname',formikProps)}
          </div>
          <div className="col-xs-12 col-md-2">
            <label className=""> MI </label>
            {this.renderField('guestmiddleinitial',formikProps, false, {not_required: true})}
          </div>

          {
            values.user.usaddress === false  && values.user.guestaddressdifferent !== true ?
            <>
              <div className="col-xs-12 col-md-6">
                  <label className="required"> Passport number</label>
                {this.renderField('guestpassportnumber',formikProps)}
              </div>
              <div className="col-xs-12 col-md-6">
                <label className="required"> Passport issued from what country </label>
                {this.renderSelectField('guestpassportcountry', formikProps, getCountrySelectOptions())}
              </div>
              <div className="col-xs-12 col-md-6">
                <label className="required"> Date of issue</label>
                {this.renderField('guestpassportissued',formikProps, false, {type: "date"})}
              </div>
              <div className="col-xs-12 col-md-6">
                <label className="required"> Passport expiration date</label>
                {this.renderField('guestpassportexpiration',formikProps, false, {type: "date"})}
              </div>
            </>
            :
            <></>
          }

          <div className="col-xs-12">
            <label className="" style={{fontFamily: "Grotesque"}}> Please ensure name aligns with your driver’s license (US) or passport (Intl.) </label>
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="required">Guest cell phone number </label>
            {this.renderField('guestcellphonenumber',formikProps)}
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="required">Guest email </label>
            {this.renderField('guestemailaddress',formikProps)}
          </div>
          <div className="col-xs-12">
            {this.renderCheckbox('guestaddressdifferent', true, formikProps, false, {toggleable: true})}
            <label className="checkbox-label">Address different from above</label>
          </div>
          {guestaddressdifferent}
          <div className="col-xs-12">
            {this.renderCheckbox('flightinfodifferent', true, formikProps, false, {toggleable: true})}
            <label className="checkbox-label">Flight info different from above</label>
          </div>
          {guestflightinfodifferent}
          <div className="col-xs-12">
            <div className="sg-pc-section-small-body ">
              <br/>
              President’s Club 2024 is an adult only incentive. Please reach out to <a href="mailto:ayuzzi@sumologic.com" style={{color: "#0046BE", textDecoration: "underline"}}>ayuzzi@sumologic.com</a> with any&nbsp;questions.
           </div>
          </div>
        </>
      )
    }

  }

  renderMultipleSelect(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0));
    let value = null;
    try {
       value = formikProps.values['user'][name] ? formikProps.values['user'][name].map(x=> {return {value: x, label:x}}) : []
    }catch (e) {
      value = []
    }

    return (
      <>
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        isMulti
        isClearable
        classNamePrefix='registration-select'
        placeholder=""
        defaultValue={{value:"",label:""}}
        value={value}
        onChange={(option) => {
          if(option && option[option.length - 1].label == "None") {
            option = [{label:"None", value:"None"}]
          }
          else if(option && option[option.length - 1].label != "None") {
            option = option.filter(x=> x.label != "None")
          }
          const { user } = formikProps.values;
          user[name] = option ? option.map(x=>x.label) : []
          formikProps.setFieldValue('user', user)
        }}
      />
      {renderOptions.other && value && value.filter(x=>x.label=="Other").length > 0 ? (
        <><br/>{this.renderField(`${name}other`, formikProps, false, {placeholder: 'Other'})}</>
      ) : ""}
      </>
    )
  }


  renderGuestAddressFields(formikProps) {
    const { values } = formikProps;
    if(values.user.guestusaddress) {
      return (
        <>
          <div className="col-xs-12 ">
            <label className="required"> Address 1 </label>
            {this.renderField('guestaddress1',formikProps)}
          </div>
          <div className="col-xs-12 ">
            <label className=""> Address 2 </label>
            {this.renderField('guestaddress2',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> City </label>
            {this.renderField('guestaddresscity',formikProps, false)}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> State </label>
            {this.renderSelectField('guestaddressstate', formikProps, getStateSelectOptions())}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> Zip Code </label>
            {this.renderField('guestpostalcode',formikProps, false)}
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency contact name </label>
            {this.renderField('guestemergencycontactname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency contact cell phone number </label>
            {this.renderField('guestemergencycontactnumber',formikProps)}
          </div>
        </>
      )
    }
    return (
      <>
      <div className="col-xs-12 ">
        <label className="required"> Address 1 </label>
        {this.renderField('guestaddress1',formikProps)}
      </div>
      <div className="col-xs-12 ">
        <label className=""> Address 2 </label>
        {this.renderField('guestaddress2',formikProps, false, {not_required: true})}
      </div>
      <div className="col-xs-12 col-md-4">
        <label className=""> City </label>
        {this.renderField('guestaddresscity',formikProps, false)}
      </div>
      <div className="col-xs-12 col-md-4">
        <label className=""> State/Province </label>
        {this.renderField('guestaddressstate',formikProps)}
      </div>
      <div className="col-xs-12 col-sm-4">
        <label className="required"> Zip Code </label>
        {this.renderField('guestpostalcode',formikProps, false)}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Country</label>
        {this.renderSelectField('guestaddresscountry', formikProps, getCountrySelectOptions())}
      </div>
      <div className="col-xs-12"></div>
      <div className="col-xs-12 col-md-6">
          <label className="required"> Passport number</label>
        {this.renderField('guestpassportnumber',formikProps)}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Passport issued from what country </label>
        {this.renderSelectField('guestpassportcountry', formikProps, getCountrySelectOptions())}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Date of issue</label>
        {this.renderField('guestpassportissued',formikProps, false, {type: "date"})}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Passport expiration date</label>
        {this.renderField('guestpassportexpiration',formikProps, false, {type: "date"})}
      </div>
      <div className="col-xs-12"></div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Emergency contact name </label>
        {this.renderField('guestemergencycontactname',formikProps)}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Emergency contact phone number </label>
        {this.renderField('guestemergencycontactnumber',formikProps)}
      </div>
      </>
    )
  }

  renderAddressFields(formikProps) {
    const { values } = formikProps;
    if(values.user.usaddress) {
      return (
        <>
          <div className="col-xs-12 ">
            <label className="required"> Address 1 </label>
            {this.renderField('override_work_address_street_1',formikProps)}
          </div>
          <div className="col-xs-12 ">
            <label className=""> Address 2 </label>
            {this.renderField('override_work_address_street_2',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> City </label>
            {this.renderField('override_work_address_city',formikProps, false)}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> State </label>
            {this.renderSelectField('override_work_address_state', formikProps, getStateSelectOptions())}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> Zip Code </label>
            {this.renderField('override_work_address_postcode',formikProps, false)}
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency contact name </label>
            {this.renderField('ecname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency contact cell phone number </label>
            {this.renderField('ecnumber',formikProps)}
          </div>
        </>
      )
    }
    return (
      <>
      <div className="col-xs-12 ">
        <label className="required"> Address 1 </label>
        {this.renderField('override_work_address_street_1',formikProps)}
      </div>
      <div className="col-xs-12 ">
        <label className=""> Address 2 </label>
        {this.renderField('override_work_address_street_2',formikProps, false, {not_required: true})}
      </div>
      <div className="col-xs-12 col-md-4">
        <label className="required"> City </label>
        {this.renderField('override_work_address_city',formikProps, false)}
      </div>
      <div className="col-xs-12 col-md-4">
        <label className="required"> State/Province </label>
        {this.renderField('override_work_address_state',formikProps)}
      </div>
      <div className="col-xs-12 col-sm-4">
        <label className="required"> Zip Code </label>
        {this.renderField('override_work_address_postcode',formikProps, false)}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Country</label>
        {this.renderSelectField('override_work_address_country', formikProps, getCountrySelectOptions())}
      </div>
      <div className="col-xs-12"></div>
      <div className="col-xs-12 col-md-6">
          <label className="required"> Passport number</label>
        {this.renderField('passportnumber',formikProps)}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Passport issued from what country</label>
        {this.renderSelectField('passportcountry', formikProps, getCountrySelectOptions())}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Date of issue </label>
        {this.renderField('passportissued',formikProps, false, {type: "date"})}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Passport expiration date</label>
        {this.renderField('passportexpiration',formikProps, false, {type: "date"})}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Emergency contact name </label>
        {this.renderField('ecname',formikProps)}
      </div>
      <div className="col-xs-12 col-md-6">
        <label className="required"> Emergency contact phone number </label>
        {this.renderField('ecnumber',formikProps)}
      </div>
      </>
    )
  }

  renderCheckbox(name, value, formikProps, customValidation = false, properties = {}) {
    const { user } = formikProps.values;
    const { checkError } = this.state;
    let erroring = checkError && (customValidation);
    const checked = user[name] == value
    return (
      <div
        className={`sg-registration-checkbox sg-registration-checkbox-${checked ? "checked" : "unchecked"} ${erroring ? 'field-erroring': "" }`}
        onClick={() => {
          const { user } = formikProps.values;
          if(properties.toggleable) {
            user[name] = !checked;
          }else {
            user[name] = value
            if (name === "usaddress") {
              user['guestusaddress'] = value
            }
          }
          formikProps.setFieldValue('user', user)
        }}
      >
        <img style={{visibility: checked ? "visible" : "hidden"}} src="/layout/checked.svg"/>
      </div>
    )
  }

  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    const empty = !formikProps.values.user[name] || formikProps.values.user[name].length <= 0
    let erroring = checkError && (empty || customValidation);
    const customStyles = properties.styles || {}
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field
        placeholder={properties.placeholder}
        component={properties.component}
        required={!properties['not_required']}
        type={properties.type}
        onKeyUp={() => properties['onKeyUp'](formikProps)}
        className={`form-control ${erroring ? 'field-erroring': "" } ${empty ? "empty" : ""}`}
        name={`user[${name}]`}
        style={customStyles}
        min={properties.date_min}
        max={properties.date_max}
      />
    )
  }

  getGroupExcursion() {
    return [
      {
        name:"[Group Excursion]",
        id: "group-excursion",
        description: null,
        date:"[Date],[Time]",
        fields:[
          {name: "Preferred level",id: "group-excursion-preferred-level", type: 'select', options: ["Easy", "Advanced"]}
        ]
      }
    ]
  }

  getActivities(formikProps) {
    const user = formikProps.values.user;

    return [
      {
        name:"Molokini Snorkel Sail",
        id: "catamaran-snorkel",
        date:"May 23 | 8:30 AM - 12:30 PM",
        description: (<>
          Ideal for those who wish to enjoy the crystal-clear waters and abundant marine life at Molokini with the chance for the rare encounter with the whimsical Hawaiian spinner dolphins.
          <br />
          <br />
          This tour combines the exhilaration of skimming along the ocean and the tranquil snorkeling of Molokini Crater. The trip is all about Molokini - rated one of the top 10 snorkel spots in the world- so it’s safe to say “it’s a must” when visiting the island. This iconic crescent-shaped islet is a beautiful reef known for its crystal-clear waters, abundant coral, colorful fish, and resting seabirds. A protected marine conservation area, this volcanic crater is only accessible by boat with a certified tour operator. The crater houses a lush reef with excellent visibility as deep as 150 feet and is home to over 250 species of fish and marine life making a snorkel trip to Molokini a premium experience.
          <br />
          <br />
          Dedicated to giving guests a meaningful encounter in the warm Hawaiian waters, naturalists share fascinating information about the playful pods of Hawaiian spinner dolphins, named for their gleeful acrobatics. On many occasions, snorkelers have been approached by dolphins while in the water; an experience some describe as “spiritual” and something that one never forgets.
        </>),
        fields:[],
        more_info: (<>
          <span style={{textDecoration: "underline"}}><b>Itinerary</b></span> (location and time scheduled to change)
          <br />
          8:30 am - Depart Hotel
          <br />
          9:15 am - Check in at Kihei boat ramp
          <br />
          9:30 am - 11:30 am - Snorkel tour
          <br />
          12:30 pm - Estimated return to Hotel
          <br />
          <br />
          <span style={{textDecoration: "underline"}}><b>Tour components</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>2 hour Exclusive snorkel charter</td>
            </tr>
            <tr>
              <td>-</td>
              <td>All snorkel gear and instruction</td>
            </tr>
          </table>
        </>)
      },
      {
        name:"Rainforest Waterfall Rappelling (Thrill seekers only)",
        id: "rainforest-waterfall-rappel",
        date:"May 23 | 8:00 am - 3:30 pm",
        description: (<>
          This award-winning “walk on the wild side” is Maui’s boldest and most adventurous expedition. Guests go RAPPELLING down cascading waterfalls and jungle walls into the private rainforest canyon featured in Steven Spielberg’s Jurassic Park.
          <br />
          <br />
          With on-site instruction from expert guides whose top priority is safety, guests will use ropes and rappelling gear to make three descents. First is a 60-foot dry cliff, where demonstrated techniques are easier to practice. Wet rappels take place from a 50-foot waterfall and a 30-foot waterfall, each ending with a short swim across a freshwater pool. After the rappels, there’s a hike along a switchback trail under a canopy of trees and ferns back to the top of the trail, for a change of clothing and sweet picnic lunch.
        </>),
        fields:[],
        more_info: (<>
          <span style={{textDecoration: "underline"}}><b>Itinerary</b></span>
          <br />
          Travel to and from the location - one hour and twenty minutes each way
          <br />
          8:00 am Depart Hotel
          <br />
          3:30 pm Estimated return to Hotel
          <br />
          <br />
          <span style={{textDecoration: "underline"}}><b>Tour components</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>Exclusive van ride to the private Puohokamoa Valley</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Hike a total of half a mile with a 350-foot elevation change</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Rappel: 40 to 100-foot jungle cliffside (for practice) then 30-foot & 50-foot waterfalls (weather permitting)</td>
            </tr>
            <tr>
              <td>-</td>
              <td>All equipment and instructions provided</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Lunch provided</td>
            </tr>
          </table>
          <br />
          <span style={{textDecoration: "underline"}}><b>Notes & restrictions</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>Participants must be in good physical condition and weigh between 70 lbs. and 250 lbs with waist measurement between 22 and 54 inches.</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Pregnant women or anyone with heart, neck or back problems are NOT allowed to participate</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Pants/shorts that are mid-thigh or longer recommended and shirt/rash guard to protect waist, (No bikinis)</td>
            </tr>
          </table>
        </>)
      },
      {
        name:"Zipline",
        id: "zipline",
        full: false,
        date:"May 23 | 9:00 am - 2:30 pm",
        description: (<>
          Step up onto the platform of Adventure on this thrilling island excursion! Surely not for the faint at heart, this is Maui’s only 8-line course, boasting the most ziplines on the island! The adventure begins with an exciting off-road ride deep into the raw West Maui Mountains. Once there, 8 amazing lines reveal the Kahoma Valley floor opening below. This excursion is sure to get your heart pumping as you soar, high above Maui’s shoreline, amidst the sweeping ocean and mountain vistas that spread forth.
          <br />
          <br />
          The first zipline is a double-racing line to allow guests the thrill of whizzing through the air in tandem with their partner. A short nature-walk down the valley side offers guests a more personal look at the lush flora and fauna of the verdant valley of Mount Kahalawai. Each line of the course gets a bit longer and a bit faster and the excitement mounts for the final and longest line of the day!
          <br />
          <br />
          The guides are fun and charismatic, making the most apprehensive guest feel brave and ready for the next zipline! The guides are also passionate about Culture and Community with a company commitment to island eco-education and conservation. Maui’s Most Eco-Conscience Zipline
        </>),
        fields:[],
        more_info: (<>
          <span style={{textDecoration: "underline"}}><b>Itinerary</b></span>
          <br />
          9:00 am Depart Hotel
          <br />
          10:00 am Check in at retail shop
          <br />
          10:30 am Zipline Tour Starts
          <br />
          1:30 pm Zipline Tour Ends
          <br />
          2:30 pm Estimated Return to Hotel
          <br />
          <br />
          <span style={{textDecoration: "underline"}}><b>Tour components</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>Approx. (3.5-4) hour (8) zip line adventure</td>
            </tr>
            <tr>
              <td>-</td>
              <td>All safety gear and instruction and Zip-line equipment</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Roundtrip Transportation; driver and guide gratuities</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Activity Staff</td>
            </tr>
          </table>
          <br />
          <span style={{textDecoration: "underline"}}><b>Notes</b></span>
          <table>
            <tr>
              <td>-</td>
              <td>Must be able to hike 2 miles on uneven terrain and not have been scuba diving within last 24 hours</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Guests with serious neck, back or joint injuries or chronic heart problems should not participate</td>
            </tr>
            <tr>
              <td>-</td>
              <td>All guests must weigh between 80-260lbs and be 10 yrs</td>
            </tr>
            <tr>
              <td>-</td>
              <td>No pregnant women allowed on this tour</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Guest must wear hiking boots or athletic shoes; Shorts and a short sleeve t-shirt are recommended</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Tour goes out rain or shine</td>
            </tr>
          </table>
        </>)
      },
      {
        name:"Longboards & Lagers",
        id: "longboards-lagers",
        date:"May 23 | 9:00 am - 2:00 pm",
        description: (<>
          When on Maui, do as the locals do – surf in the morning and hit the local brewery for Happy Hour! In this adventure, you will be treated to private surf lessons at The Cove Beach Park, one of Maui’s top surfing spots for beginners and experts alike. Being on Maui is the perfect time and place to give it a go and try your hand at SURFING. You will be paired with professional and friendly surfers who will show you the steps to “hanging ten” like a pro.
          <br />
          <br />
          From there, you will be shuttled to Maui Brewing Company for lunch and to sample some of their delicious brews.
        </>),
        fields:[],
        more_info: (<>
          <span style={{textDecoration: "underline"}}><b>Itinerary</b></span>
          <br />
          9:00 am - Depart hotel
          <br />
          9:15 am - Check in for surf lesson
          <br />
          9:30 am - 11:30 am Surf lesson
          <br />
          12:00 pm - Arrive at Maui Brewing Company
          <br />
          12:00 pm - 1:30 pm - Lunch and beer tasting
          <br />
          2:00 pm - Estimated return to hotel
          <br />
          <br />
          <span style={{textDecoration: "underline"}}><b>Tour components</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>(2) hour exclusive surf lesson with certified surf experts</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Maui Brewing Company lunch and beer tasting</td>
            </tr>
          </table>
        </>)
      },
      {
        name:"Deep Sea Fishing",
        id: "deep-sea-fishing",
        date:"May 23 | 7:00 am - 12:45 pm",
        description: (<>
          What’s the Catch?! The waters off the Maui coast offer the best conditions for fishing. Thanks to the outstanding weather, which is sunny almost 360 days of the year, skippers can find fish virtually every day. Whether a novice or fishing pro, guests quickly discover that fishing off the “Valley Isle” is unlike any other activity. The day starts with some exhilarating high-speed trolling for some big game fish like wahoo and mahi mahi.
          <br />
          <br />
          Then it’s time for the captain to slow it down for some bottom fishing along the southwest of Maui. Nothing connects guests to the true Hawaiian lifestyle is like a day on the water. Today’s fishers are in for a treat as they board their fishing boats ready for a bottom fishing/sportfishing adventure just for them. Any day at sea is a good day, no matter what’s the catch!
        </>),
        fields:[],
        more_info: (<>
          <span style={{textDecoration: "underline"}}><b>Suggested itinerary</b></span>
          <br />
          7:00 am Depart hotel
          <br />
          8:00 am Check in at harbor
          <br />
          8:00 am – 12:00pm Sportfishing Experience
          <br />
          12:45 pm Estimated return to hotel
          <br />
          <br />
          <span style={{textDecoration: "underline"}}><b>Tour components</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>Exclusive use of fishing vessel with captain & crew</td>
            </tr>
            <tr>
              <td>-</td>
              <td>All tackle and bait necessary for boat</td>
            </tr>
            <tr>
              <td>-</td>
              <td>Boxed Lunch </td>
            </tr>
          </table>
          <br />
          <span style={{textDecoration: "underline"}}><b>Notes</b></span>
          <br />
          <table>
            <tr>
              <td>-</td>
              <td>Combo Bottom Fishing/Trolling Charter with Max 16 anglers + 6 riders</td>
            </tr>
            <tr>
              <td>-</td>
              <td>NOT RECOMMENDED FOR GUESTS PRONE TO SEA SICKNESS</td>
            </tr>
          </table>
        </>)
      },
      {
        name:"Golf at Wailea Emerald Course",
        id: "golf",
        date:"May 23 | 8:15 am - 2:30 pm",
        description: (<>
          With views of the Pacific and Mount Haleakala from all 18 holes, the course’s leisurely layout, crafted by Robert Trent Jones II, is a tropical playground designed for pure enjoyment. A range of tee boxes at each hole means that you can tailor the course to suit your playing level.
          <br />
          <br />
          The Emerald course is intended to be fun for all levels of players. There are less forced carries, fairways are wider, and greens are more approachable. Perfectly manicured, with volcano and ocean views, and surrounded by a colorful tropical garden the Emerald Course is a delight to play. When it opened ‘Emerald’ was voted by Golf Magazine as one of the top 10 new courses in the land This excursion includes a shared cart, rental clubs and shoes (if needed).
        </>),
        fields: this.getGolfFields(formikProps),
        more_info: (<>
          <span style={{textDecoration: "underline"}}><b>Itinerary</b></span>
          <br />
          8:15 am - Depart hotel
          <br />
          8:50 am - Tee off
          <br />
          1:00 pm - Lunch at Clubhouse (all foursomes)
          <br />
          2:30 pm - Return to hotel
        </>)
      },
      {
        top_description: (<>
          Four Seasons Spa (50 minute treatments)
          <br />
          <br />
          <span style={{fontFamily: "Grotesque", color: "#64696C", marginTop: "-10px", display: "block"}}>
          According to Hawaiian legend, if one wishes to nurture the body, relax the mind and rejuvenate the spirit, water is seen as the cure-all. Located at the water's edge, the Spa at Four Seasons Resort Maui at Wailea is a sanctuary of natural beauty and serenity, where healing traditions of Hawaii and cultures of the Pacific Rim are honored through the incorporation of medicinal plants, rituals and aesthetics in all spa services.
          <br />
          <br />
          The variety of treatments available, from the traditional Swedish and deep tissue style of massage or a dramatic healing facial. Therapists are also extremely skilled and they love to personalize the treatment to our guest's needs and preferences.
          <br />
          <br />
          Spa services will be scheduled from 9:00 am - 4:00 pm on May 23. We will contact you in April and provide a sign-up sheet with available times for your selected treatment. Select from three options of spa treatments available for this excursion:
          </span>
        </>),
        name:"Spa: Swedish Massage",
        id: "spa-swedish-massage",
        date:"May 23 | Appointment TBD",
        description: (
          <>
          This classic massage promotes relaxation with gentle, rolling strokes.
          </>
        ),
        selectedDescription: (
          <>
          <span style={{color: "#21B9CC"}}><i>We will contact you to arrange your appointment time</i></span>
          <br />
          </>
        ),
        fields:[]
      },
      {
        name:"Spa: Deep-Tissue Massage",
        id: "spa-deep-tissue-massage",
        date:"May 23 | Appointment TBD",
        description: "This therapeutic massage penetrates targeted muscles to loosen tight areas.",
        selectedDescription: (
          <>
          <span style={{color: "#21B9CC"}}><i>We will contact you to arrange your appointment time</i></span>
          <br />
          </>
        ),
        fields:[]
      },
      {
        name:"Spa: Dramatic Healing Facial",
        id: "spa-dramatic-healing-facial",
        date:"May 23 | Appointment TBD",
        description: "This high-performance facial enhances the skin’s ability to repair and re-strengthen itself using a clinically proven Dramatic Healing Serum, in combination with a deep-muscle facial massage.",
        selectedDescription: (
          <>
          <span style={{color: "#21B9CC"}}><i>We will contact you to arrange your appointment time</i></span>
          <br />
          </>
        ),
        fields:[]
      }
    ]
  }

  getGolfFields(formikProps) {
    const user = formikProps.values.user;

    const fields = [
      {name:"Do you need clubs?", id: "golfneedclubs", type: 'yesno'},
      {name:"Are you left or right handed?", id: "golfhanded", type: 'select', options: ["Left handed", "Right handed"], dont_show: user.golfneedclubs != "Yes"},
      {name:"Do you need shoes?", id: "golfneedshoes", type: 'yesno'},
      {name:"Shoe size", id: "golfshoesize", type: 'select', options: getShoeSizeOptions(), dont_show: user.golfneedshoes != "Yes"},
    ]
    const fieldsGuest = [
      {name:"Does your guest need clubs?", id: "guestgolfneedclubs", type: 'yesno'},
      {name:"Is your guest left or right handed?", id: "guestgolfhanded", type: 'select', options: ["Left handed", "Right handed"], dont_show: user.guestgolfneedclubs != "Yes"},
      {name:"Does your guest need shoes?", id: "guestgolfneedshoes", type: 'yesno'},
      {name:"Guest shoe size", id: "guestgolfshoesize", type: 'select', options: getShoeSizeOptions(), dont_show: user.guestgolfneedshoes != "Yes"},
    ]
    const fieldsBoth = [
      {name:"Do you need clubs?", id: "golfneedclubs", type: 'yesno'},
      {name:"Are you left or right handed?", id: "golfhanded", type: 'select', options: ["Left handed", "Right handed"], dont_show: user.golfneedclubs != "Yes"},
      {name:"Does your guest need clubs?", id: "guestgolfneedclubs", type: 'yesno'},
      {name:"Is your guest left or right handed?", id: "guestgolfhanded", type: 'select', options: ["Left handed", "Right handed"], dont_show: user.guestgolfneedclubs != "Yes"},
      {name:"Do you need shoes?", id: "golfneedshoes", type: 'yesno'},
      {name:"Shoe size", id: "golfshoesize", type: 'select', options: getShoeSizeOptions(), dont_show: user.golfneedshoes != "Yes"},
      {name:"Does your guest need shoes?", id: "guestgolfneedshoes", type: 'yesno'},
      {name:"Guest shoe size", id: "guestgolfshoesize", type: 'select', options: getShoeSizeOptions(), dont_show: user.guestgolfneedshoes != "Yes"},
    ]
    if(user.chosenactivities &&
      user.chosenactivities.length >= 1 &&
      user.chosenactivities[0] == 'golf' &&
      user.selectedactivitypersoncount1 == "Both") {
        return fieldsBoth;
    }
    if(user.chosenactivities &&
      user.chosenactivities.length >= 2 &&
      user.chosenactivities[1] == 'golf' &&
      user.selectedactivitypersoncount2 == "Both") {
        return fieldsBoth;
    }
    if(user.chosenactivities &&
      user.chosenactivities.length >= 1 &&
      user.chosenactivities[0] == 'golf' &&
      user.selectedactivitypersoncount1 == "Guest") {
        return fieldsGuest;
    }
    if(user.chosenactivities &&
      user.chosenactivities.length >= 2 &&
      user.chosenactivities[1] == 'golf' &&
      user.selectedactivitypersoncount2 == "Guest") {
        return fieldsBoth;
    }
    return fields
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }
    return (
      <>
        <Select
          className={erroring ? "registration-select-erroring" : ""}
          options={options}
          classNamePrefix='registration-select'
          defaultValue={{value:"",label:""}}
          value={options ? value : ""}
          onChange={(option) => {
            const { user } = formikProps.values;
            user[name] = option.value
            formikProps.setFieldValue('user', user)
          }}
          filterOption={this.customFilter}
        />
      {renderOptions.other && value && value.label=="Other" ? (
        <><br/>{this.renderField(`${name}other`, formikProps, false, {placeholder: 'Other'})}</>
      ) : ""}
      </>
    )
  }

  customFilter(option, searchText) {
    const ones = (option.label||"").toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }

  customPasswordValidation(value, otherValue) {
    if(!value) {
      return true;
    }
    if(!(value == otherValue)){
      return true;
    }
    if(value.length < 6) {
      return true;
    }
    return false;
  }

  submitFormButtonOnClick(formikProps) {
    const { values } = formikProps;
    if(!this.isPageOneValid(formikProps) || !this.isPageTwoValid(formikProps)) {
      this.setState({
        pageThreeError: "One or more fields is invalid, please go back to check before submitting again.",
        checkError: true
      })
    }
    else if(this.isEmailPersonal(values['user']['email'])) {
      this.setState({
        pageThreeError: "You cannot use a personal email for this event.",
        checkError: true
      })
    }
    else {
      this.setState({
        pageOneError: null,
        pageTwoError: null,
        pageThreeError: null,
        checkError: true,
        fullPageError: null,
      })
      formikProps.handleSubmit()
    }
  }

  formURL() {
    return `/registration`
  }

  nextPage(formikProps) {
    const { page } = this.state;
    if(page == 1 && !this.isPageOneValid(formikProps)) {
      this.setState({
        checkError: true,
        pageOneError: "Please make sure all fields are filled out correctly."
      })
      return;
    }
    else if(page == 2 && this.checkActivityErrors(formikProps, true)) {
      return;
    }
    else if(page == 2 && !this.isPageTwoValid(formikProps)) {
      this.setState({
        checkError: true,
        pageTwoError: "Please make sure all fields are filled out correctly."
      })
      return;
    }
    window.scrollTo(0, 0);
    this.setState({
      page: page + 1,
      pageOneError: null,
      pageTwoError: null,
      pageThreeError: null,
      checkError: null,
      error: null,
      activityError: null,
    })
  }

  checkActivityErrors(formikProps, forceStateChange = false) {
    const user = formikProps.values.user
    const {selectedactivitypersoncount1, selectedactivitypersoncount2} = user;
    let error = null
    if(this.isBringingGuest(formikProps)) {
      if(!selectedactivitypersoncount1 && user.chosenactivities.length > 0) {
        error = 'Please select who is attending which activity'
      }
      if(!selectedactivitypersoncount2 && user.chosenactivities.length > 1) {
        error = 'Please select who is attending which activity'
      }
      if(user.chosenactivities.length > 1 && selectedactivitypersoncount1 == selectedactivitypersoncount2) {
        error = 'You may only select one activity per person.'
      }
      if(selectedactivitypersoncount1 == "Both" && user.chosenactivities.length > 1) {
        error = 'You may only select one activity per person.'
      }
      if(selectedactivitypersoncount2 == "Both" && user.chosenactivities.length > 1) {
        error = 'You may only select one activity per person.'
      }
      // if((selectedactivitypersoncount1 == "" || selectedactivitypersoncount2 == "") && !(selectedactivitypersoncount1 === "Both" || selectedactivitypersoncount2 === "Both")) {
      //   error = 'You must select an activity for each person.'
      //   error = 'You have selected only one individual excursion. Please select another one. You can also continue without selecting a second individual excursion.'
      // }
    } else {
      if(user.chosenactivities && user.chosenactivities.length > 1) {
        error = "You may only select one activity per person."
      }
    }
    if(error) {
      if(forceStateChange) {
        this.setState({
          checkError: true,
          pageTwoError: "Please make sure all fields are filled out correctly.",
          activityError: error
        })
      }
      return true;
    }
    return false;
  }

  prevPage(formikProps) {
    const { page } = this.state;
    const { user } = formikProps.values;

    window.scrollTo(0, 0);
    if (page === 2) {
      user["chosenactivity1"] = "";
      user["chosenactivity2"] = "";
      user['chosenactivities'] = [];
      user["selectedactivitypersoncount1"] = "";
      user["selectedactivitypersoncount2"] = "";
      formikProps.setFieldValue('user', user);
    }
    this.setState({
      page: 1
    })
  }

  participantKeys() {
    return [
      'name_first',
      'name_last',
      'email',
      'override_work_address_street_1',
      'override_work_address_street_2',
      'override_work_address_city',
      'override_work_address_state',
      'override_work_address_postcode',
      'override_work_address_country'
    ];
  }

  pictureKeys() {
    return [
      'picture_1',
      'picture_2',
      'picture_3'
    ]
  }

  render() {
    const { user } = this.props;
    return (
      <div className="registration-form">
        {/* {this.renderTester()} */}
        <Formik
          initialValues={{
            user: {
              name_first: "",
              name_last: "",
              middleinitial: "",
              cellphonenumber: "",
              email: "",
              override_work_address_street_1: "",
              override_work_address_street_2: "",
              override_work_address_city: "",
              override_work_address_state: "",
              override_work_address_postcode: "",
              ecname: "",
              ecnumber: "",
              passportissued: "",
              passportnumber: "",
              passportexpiration: "",
              passportcountry: "",
              bringingguest: null,
              guestaddress1: "",
              guestaddress2: "",
              guestaddresscity: "",
              guestaddressstate: "",
              guestemergencycontactname: "",
              guestemergencycontactnumber: "",
              guestaddresscountry: "",
              guestpassportissued: "",
              guestpassportnumber: "",
              guestpassportexpiration: "",
              guestpassportcountry: "",
              guestfirstname: "",
              guestlastname: "",
              guestmiddleinitial: "",
              guestcellphonenumber: "",
              guestemailaddress: "",
              guestaddressdifferent: "",
              arrivaldate: "",
              departuredate: "",
              roompreference: "",
              golfneedclubs: "",
              golfhanded: "",
              golfneedshoes: "",
              golfshoesize: "",
              jacketshirtsizeself: "",
              jacketshirtsizeguest: "",
              shoesizeself: "",
              shoesizeguest: "",
              chosenactivity1: "",
              chosenactivity2: "",
              selectedactivitypersoncount1: "",
              selectedactivitypersoncount2: "",
              // preferredbeverage: "",
              // preferredalcoholicbeverage: "",
              // preferredmixer: "",
              // preferredsnack: "",
              foodrestrictions: "",
              foodrestrictionsother: "",
              guestfoodrestrictions: "",
              guestfoodrestrictionsother: "",
              otherquestions: "",
              usaddress: true,
              guestusaddress: true,
              chosenactivities: [],
              attendinggroupexcursion: null,
              groupexcursionpeoplecount:"",
              groupexcursionlevel: "",
              guestgolfneedclubs:"",
              guestgolfhanded:"",
              guestgolfneedshoes:"",
              guestgolfshoesize:"",

              airlineinbound: "",
              flightnumberinbound: "",
              arrivaldateinbound: "",
              arrivaltimeinbound: "",
              airlineoutbound: "",
              flightnumberoutbound: "",
              departuredateoutbound: "",
              departuretimeoutbound: "",
              airlineinboundguest: "",
              flightnumberinboundguest: "",
              arrivaldateinboundguest: "",
              arrivaltimeinboundguest: "",
              airlineoutboundguest: "",
              flightnumberoutboundguest: "",
              departuredateoutboundguest: "",
              departuretimeoutboundguest: "",
              flightinfodifferent: "",

              picture_1: "",
              picture_2: "",
              picture_3: "",

              hobby: "",
              song: "",
              quote: ""
            },

          }}

          onSubmit={(values, actions) => {
            if(this.state.submitting) {
              return;
              console.log("Double click prevention")
            }

            this.setState({
              submitting: true
            })
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");
            const chosenactivities = values.user.chosenactivities || []
            values.user.chosenactivity1 = chosenactivities.length >= 1 ? chosenactivities[0] : null
            values.user.chosenactivity2 = chosenactivities.length >= 2 ? chosenactivities[1] : null
            var form_data = new FormData();
            let timezone = null;

            ["foodrestrictions","guestfoodrestrictions"].map(x=> {
              if (
                (values.user[x] !== undefined && values.user[x] !== '') &&
                (values.user[x+"other"] !== undefined && values.user[x+"other"] !== '')
              ) {
                let arr = values.user[x].concat([values.user[x+"other"]])
                arr = arr.filter(item => item);
                return values.user[x] = arr
              }
              return values.user[x] = values.user[x]
            })
            for ( var key in values['user'] ) {
              if (key !== 'chosenactivities') {
                if (this.participantKeys().includes(key)) {
                  form_data.append(`participant[${key}]`, values['user'][key]);
                  // } else if (this.pictureKeys().includes(key)){
                  //   form_data.append(key, values['user'][key]);
                }else {
                  form_data.append(`metadata[${key}]`, values['user'][key])
                }
              }
            }

            fetch(this.formURL(), {
              method: 'POST',
              redirect: "manual",
              body: form_data,
              headers: {
                "X-CSRF-Token": token
              }
            })
              .then(response => {
                try {
                  console.log(response)
                  return response.json();
                }
                catch(e) {
                  window.location.href="/registered";
                }
              })
              .then(json => {
                if (json.error == null) {
                  this.setState({
                    submitting: false
                  })
                  window.location.href = "/registered";
                } else {
                  this.setState({
                    fullPageError: json.error,
                    submitting: false
                  })
                }
              });
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderPageOne(formikProps)}
              {this.renderPageTwo(formikProps)}
              {this.renderPageThree(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default RegistrationForm;
