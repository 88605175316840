import React from "react";
import PropTypes from "prop-types";
import SurveyModal from "./SurveyModal"
import   { formatToTimeZone } from 'date-fns-timezone';
import { getCurrentUTC, getTimeZoneAbbreviation } from "@utility/TimeUtils"
class SurveyItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderTrackAndType(session) {
    const { session_tracks } = session;
    return (
      <div className="sg-resource-center-text-track-type">
        {session.session_type}
      </div>
    );
  }

  renderTopColor(complete) {
    return (
      <div
        className={`sg-resource-center-top-color sg-survey-${complete ? "green" : "red"}`}
      />
    );
  }

  render() {
    const { session, surveyCompleted } = this.props;
    return (
      <div className="col-sm-4" style={{padding: "0 5px"}}>
        <SurveyModal completed={surveyCompleted} session={session} completeSurveyCallback={this.props.completeSurveyCallback} outerClassNames="sg-resource-link">
          <div className="sg-resource-wrapper">
            {this.renderTopColor(surveyCompleted)}
            <div className="sg-resource-center-text-wrapper">
              {this.renderTrackAndType(session)}
              <div className="sg-resource-center-text-title">{session.title}</div>
              <div className={`sg-survey-item-card-taken-text ${surveyCompleted ? "sg-survey-item-card-taken-completed" : ""}`}>
                {surveyCompleted ? "Complete" : "Incomplete"}
              </div>
            </div>
          </div>
        </SurveyModal>
      </div>
    );
  }
}

export default SurveyItem;
