import React from "react";
import PropTypes from "prop-types";
import ProfileForm from "./ProfileForm";
import ProfilePicture from "./ProfilePicture";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureDirty: false
    }
  }

  render() {
    const { user } = this.props;
    return (
      <>
        <ProfileForm {...this.props} pictureDirty={this.state.pictureDirty}/>
      </>
    );
  }
}

export default ProfilePage;
