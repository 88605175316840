import React from "react";
import PropTypes from "prop-types";
import SurveyItem from "./SurveyItem";
import Select from "react-select";
import { subHours, format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { getCurrentTime } from "@utility/TimeUtils"

class SurveyDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeOverride: getCurrentTime(props.user),
      selectedSortStatus: {value: "all", label: "All"},
      surveys_completed: props.surveys_completed.sessions || [],
    }

    this.setSortStatus = this.setSortStatus.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.addCompletedSurvey = this.addCompletedSurvey.bind(this);
  }

  componentDidMount() {
    setInterval(this.updateTime, 3000);
  }

  updateTime() {

    this.setState({
      timeOverride: getCurrentTime(this.props.user)
    })
  }

  getCurrentTime() {
    return getCurrentTime(this.props.user);
  }

  getSortTrackOptions() {
    return [
      {label: "All", value: "all"},
      {label: "Incomplete", value: 'incomplete'},
      {label: "Complete", value: 'complete'},
    ];
  }

  setSortStatus(option) {
    this.setState({
      selectedSortStatus: option
    })
  }

  renderSortStatus() {
    const { selectedSortStatus } = this.state;
    return (
      <div className="video-sortby-button">
        <Select
          className=""
          options={this.getSortTrackOptions()}
          classNamePrefix='sg-select'
          value={selectedSortStatus}
          onChange={(option) => {
            this.setSortStatus(option)
          }}
        />
      </div>
    )
  }

  renderFilters() {
    return (
      <div className="sg-filters-wrapper sg-filter-survey">
        <div className="sg-filter col-sm-4">
          <div className="certifications-filter-text">Filter by status</div>
          {this.renderSortStatus()}
        </div>
      </div>
    );
  }

  getFilteredSessions() {
    const { user } = this.props;
    const { selectedSortStatus, surveys_completed } = this.state;
    let { sessions } = this.props;
    sessions = sessions.filter(x=>
      user.session_uuids.find(y=> y==x.uuid)
      ||
      surveys_completed.find(y => y == x.uuid)
    )
    sessions= sessions.filter(x=> x.session_type != "Quiz Time" && x.session_type!="Film Festival");
    //Filter Time
    sessions = sessions.filter(x=>
      addMinutes(new Date(x.date_and_time), x.length_minutes - 5) < this.getCurrentTime()
    )

    sessions = sessions.filter(x=>
      ['OOP_02','OOP_03','OCL_02','OCL_01','SOP_02'].findIndex(y => y== x.code) == -1
    )
    switch(selectedSortStatus.value) {
      case "complete":
        sessions = sessions.filter(x=>this.getSurveyCompleted(x))
        break
      case "incomplete":
        sessions = sessions.filter(x=>!this.getSurveyCompleted(x))
        break
    }
    return sessions.sort((a,b) => compareAsc(
      addMinutes(new Date(a.date_and_time), this.getSurveyCompleted(a) ? 10000000 : 0),
      addMinutes(new Date(b.date_and_time), this.getSurveyCompleted(b) ? 10000000 : 0)
    ))
  }

  getSurveyCompleted(session) {
    const { surveys_completed } = this.state;
    if(session) {
      return surveys_completed.filter(x => x == session.uuid).length > 0;
    }
    return false;
  }

  renderSurveys() {
    const { user } = this.props;
    const { selectedSortStatus } = this.state;
    const sessions = this.getFilteredSessions();
    if(sessions.length > 0 || selectedSortStatus.value != 'all') {
      return sessions.map(session =>
        <SurveyItem
          key={`survey-item-${session.uuid}`} 
          user={user}
          session={session}
          surveyCompleted={this.getSurveyCompleted(session)}
          completeSurveyCallback={this.addCompletedSurvey}
        />
      )
    }
    return (
      <div className="sg-surveys-no-surveys">
        You have no surveys, yet. Surveys will be posted after a session is over.
      </div>
    )
  }

  addCompletedSurvey(session) {
    const { surveys_completed } = this.state;
    this.setState({
      surveys_completed: surveys_completed.concat([session.uuid])
    })
  }

  render() {
    const { channels, resources } = this.props;
    const { resourceItems } = this.state;
    return (
      <>
        <div className="video-sortby" style={{width: "100%"}}>
          {this.renderFilters()}
          <div className="clear-both" />
        </div>
        <br/>
        <div className="sg-resources-wrapper sg-survey-wrapper">
          {this.renderSurveys()}
        </div>
        <br/>
      </>
    );
  }
}

export default SurveyDashboard;
