import React from "react";
import PropTypes from "prop-types";
import moment from 'moment-timezone';
import { areChatroomsOpen } from "@utility/TimeUtils"
class Info extends React.Component {
  // Question and answer in the following format

  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          question: "What sessions do I need to attend for Kickoff 2022?",
          answer: (
            <ul>
              <li>
                If you are in the go-to-market organization, it is mandatory you attend all sessions during the SumoYou program.  These sessions will be pre-populated into your schedule on the virtual platform
              </li>
              <li>
                If you are in a non GTM role (engineering, GNA, product management), it is mandatory that you attend all executive general sessions and keynotes (outside guest speakers).  These sessions will be pre-populated into your schedule on the virtual platform
              </li>
            </ul>
          )
        },
        {
          question: "Is there any “pre-work” prior to SumoYou?",
          answer: "Yes, although limited, the GTM organization will be advised of any pre-work prior to the event."
        },
        {
          question: "When are sessions taking place?",
          answer: (
            <ul>
              <li>
                Americas and EMEA sessions will begin at 8:00 am PT Monday - Thursday the week of 2/22 and 3/1
              </li>
              <li>
                APAC and Japan sessions will begin at 5:00 pm PT Tuesday - Friday the week of 2/22 and 3/1
              </li>
            </ul>
          )
        },
        {
          question: "What if I miss a session?",
          answer: (<>
            Approval must be received from your manager if you are unable to attend a required session.  You will be able to make up a missed session by either attending the evening program offering for APAC and Japan or by watching the recorded session located in the <a href="/session_replays">on-demand section</a> of the virtual platform.
          </>)
        },
        {
          question: "Do I have to view the sessions in the virtual platform or can I just connect to zoom directly?",
          answer: "Although you can add all sessions to your personal google calendar, the links will take you to the virtual platform and not directly to zoom."
        },
        {
          question: "Will sessions be recorded?",
          answer: "Yes, all sessions will be recorded and available on the virtual platform through March 10.  After March 10, they will be available in Highspot. "
        },
        {
          question: "Will I be tested and certified on the material?",
          answer: "Yes. There will be quizzes during SumoYou for the GTM organization and you will have a post-SumoYou certification exam. Stay tuned for more details on the post-certification exam.",
        },
        {
          question: "If I have technical problems, who do I contact?",
          answer: (
            <ul>
              <li>
                Non GTM groups - please reach out to us at <b>#kickoff-help</b>
              </li>
              <li>
                 GTM organization - please reach out to us at <b>#sumoyou-help</b>
              </li>
            </ul>
          )
        },
        
      ]
    }
  }


  renderFaq() {
    const { questions } = this.state;
    return questions.map((q,index)=>
      (
        <div key={`question-${index}`} className="sq-info-faq-question-and-answer">
          <div className="sq-info-faq-question">
            {q.question}
          </div>
          <div className="sq-info-faq-answer">
            {q.answer}
          </div>
        </div>
      )
    )
  }

  render() {
    return (
      <>
        <div className="sq-info-faq-container">
          {this.renderFaq()}
        </div>
      </>
    );
  }
}

export default Info;
