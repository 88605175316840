import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"
import Table from "rc-table";
import "chart.js";
import { LineChart, AreaChart, PieChart, ColumnChart } from "react-chartkick";
// class UsersDashboard extends React.Component {
class PlatformReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getUserRegValues() {
    return this.props.values;
  }

  componentDidMount() {

  }


  colorPalatte() {
    return [
      "#000099",
      "#0046BE",
      "#0092D2",
      "#63CEDB",
      "#E5F4FB",
      "#000099",
      "#0046BE",
      "#0092D2"
    ];
  }

  getRegistrantsByGroupAttendence() {
    let {attendees} = this.props;
    attendees = this.getFilteredRegistrants();
    const array_1 =  [
      [
        "Attending",
        attendees.length === 0 ?
        0
        :
        attendees.filter(x=>x.registration_answers['attendinggroupexcursion'] == "true").map(x=> {
          if(x.registration_answers['groupexcursionpeoplecount'] == "true" && x.registration_answers['groupexcursionpeoplecount'] == "Both"){//bringing_guest //group_excursion_people_count
            return 2
          }
          else {
            return 1
          }
        }).reduce((a,b) => a + b)
      ],
      [
        "Not Attending",
        attendees.length === 0 ?
        0
        :
        attendees.map(x=> {
          if(x.registration_answers['attendinggroupexcursion'] != "true") {
            if(x['registration_answers']['bringing_guest'] == "true"){
              return 2
            }
            return 1
          }
          if(x['registration_answers']['bringing_guest'] == "true" && x['registration_answers']['group_excursion_people_count'] != "Both"){//bringing_guest //group_excursion_people_count
            return 1
          }
          return 0
        }).reduce((a,b) => a + b)
      ]
    ]
    const array = array_1.map(x=> x);
    return array;
  }

  isAttendeeGTM(user) {
    const notGoToMarketUserTypes = [
      "Engineering",
      "Facilities",
      "Finance",
      "Human Resources",
      "Legal",
      "Product Management",
      "Invited Guest",
    ]
    if(user && notGoToMarketUserTypes.findIndex(type => type == user['registration_answers']["22"]) != -1) {
      return "non-gtm"
    }
    return "gtm"
  }


  getRegistrantsByRegion() {
    let {attendees} = this.props;
    attendees = attendees.filter(x=>x.email.indexOf("@jaguardesignstudio") == -1);

    const attendee_types = attendees.map(x=> x['address_country']).filter((x,i,t) => t.indexOf(x) == i);

    let attendee_object = attendee_types.filter(x=>x).map(x=>
      [x,attendees.filter(y=> y['address_country'] == x).length]
    )
    return attendee_object;
  }

  getTotalRegistrants() {
    const { attendees, users} = this.props;
    return this.getFilteredRegistrants().length;
  }

  getTotalAttendees() {
    const attendees =  this.getFilteredRegistrants().map(x=> {
      const bringing_guest = this.getMetaDataValue('bringingguest', x)
      if(bringing_guest != "true") {
        return 1
      }else {
        return 2
      }
    })
    if(attendees && attendees.length > 0 ) {
      return attendees.reduce((x,y)=>x+y);
    }
    return 0;
  }

  getFilteredRegistrants() {
    const { attendees, users } = this.props;
    // TODO: Filter out jaguars again when live
    return attendees.filter(x=>
      x.email.indexOf("@jaguardesignstudio") == -1 && x['registration_answers'] && !x.test_flag
    )
    // return attendees;
  }

  renderBubbles() {
    return (
      <div className="row">
        <div className="col-xs-12" style={{verticalAlign: "top",  display: "flex"}}>
          <div className="sg-platform-report-circle">
            <div className="sg-platform-report-circle-inner">
              <div className="sg-platform-report-circle-title">
                Total
                <br/>
                Registrations
              </div>
              <div className="sg-platform-report-circle-count">
                {this.getTotalRegistrants()}
              </div>
            </div>
          </div>

          <div className="sg-platform-report-circle" style={{backgroundColor:"#0092D2"}}>
            <div className="sg-platform-report-circle-inner">
              <div className="sg-platform-report-circle-title">
                Total
                <br/> Attendees
              </div>
              <div className="sg-platform-report-circle-count">
                {this.getTotalAttendees()}
              </div>
            </div>
            <div className="sg-platform-circle-bottom-text">
              (including guests)
            </div>
          </div>

          <div className="sg-platform-report-circle" style={{backgroundColor:"#63CEDB"}}>
            <div className="sg-platform-report-circle-inner">
              <div className="sg-platform-report-circle-title">
                Attendees
                <br/> Anticipated
              </div>
              <div className="sg-platform-report-circle-count">
                100
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getOverTime() {
    const registrants = this.getFilteredRegistrants();
    const startDate = new Date("02-26-2024");
    const currentDate = new Date();
    let iDate = startDate; //Iterator Date
    const dateObject = {}
    while(iDate < currentDate) {
      iDate.setDate(iDate.getDate() + 1);
      dateObject[startDate] = registrants.filter(x=> new Date(x.registered_at) <= iDate).length
    }
    return dateObject
  }

  getMetaDataValue(slug, participant) {
    let value = '';
    let matched_block = participant["metadata"].filter(m => m.field_slug === slug);
    if (matched_block !== undefined) {
      value = matched_block[0]["value"]
    }
    return value;
  }

  getRegistrantsByActivities() {
    let {activities} = this.props;
    const attendees = this.getFilteredRegistrants();
    let attendee_object = activities.items.map(activity =>{
      let count = 0;
      count = attendees.filter(x=>
        this.getMetaDataValue('chosenactivity1', x) == activity.slug
      ).map(x=> {
        if(this.getMetaDataValue('bringingguest', x) != "true") {
          return 1
        }else {
          if(this.getMetaDataValue('selectedactivitypersoncount1', x) == "Both"){
            return 2;
          }
          return 1
        }
      })
      count = count.concat(
        attendees.filter(x=>
          this.getMetaDataValue('chosenactivity2', x) == activity.slug
        ).map(x=> 1)
      )
      return [
        activity.name,
        count && count.length > 0 ? count.reduce((x,y)=>x+y) : 0
      ]
    })
    return attendee_object;
  }

  getFoodRestrictions() {
    const attendees = this.getFilteredRegistrants();
    let foodRestrictions = {}
    attendees.forEach(attendee => {
      if (this.getMetaDataValue('foodrestrictions', attendee) !== '') {
        let foodAttendee = this.getMetaDataValue('foodrestrictions', attendee).split(',').filter(elm => elm);
        if (foodAttendee.includes('Other')) {
          foodAttendee = ["Other"];
        }
        foodAttendee.forEach(res => {
          if (res !== undefined && res !== " ") {
            if (foodRestrictions[res] === undefined) {
              foodRestrictions[res] = 1;
            } else {
              foodRestrictions[res] = foodRestrictions[res] + 1
            }
          }
        })
        let foodGuest = this.getMetaDataValue('guestfoodrestrictions', attendee).split(',').filter(elm => elm);
        if (foodGuest.includes('Other')) {
          foodGuest = ["Other"];
        }
        foodGuest.forEach(res => {
          if (res !== undefined && res !== " ") {
            if (foodRestrictions[res] === undefined) {
              foodRestrictions[res] = 1;
            } else {
              foodRestrictions[res] = foodRestrictions[res] + 1
            }
          }
        })
      }
    });
    var result = Object.keys(foodRestrictions).map((key) => [key, foodRestrictions[key]]);
    return result;
  }

  renderFrame() {
    // <h1 className="sg-platform-report-graph-sub-title text-center"> By Group Activity Attendance</h1>
    // <PieChart colors={this.colorPalatte()} legend={false} data={this.getRegistrantsByGroupAttendence()} />
    let attendeeCount = this.getFilteredRegistrants().length;
    return (
      <div className="row" style={{display:"flex"}}>
        <div className="col-xs-12 col-md-8">
          <div className="row sg-platform-report-section">
            {this.renderBubbles()}
            <div className="col-xs-12">
              <h1 className="sg-platform-report-graph-title"> Registrants over time</h1>
              <LineChart
                data={this.getOverTime()}
              />
            </div>
          </div>
          <div className="row sg-platform-report-section">
            {this.renderReports()}
          </div>
        </div>
        <div className="col-xs-12 col-md-4">
          <div className="row sg-platform-report-section"  style={{height: "100%"}}>
            <div className="col-xs-12">
              <h1 className="sg-platform-report-graph-title text-center"> Registrants breakdown</h1>
              <h1 className="sg-platform-report-graph-sub-title text-center"> By Individual Activities </h1>
              {
                attendeeCount === 0 ?
                <div style={{textAlign: "center", margin: "20px 0px"}}>No registrants yet</div>
                :
                <PieChart colors={this.colorPalatte()} legend={false} data={this.getRegistrantsByActivities()} />
              }
              <h1 className="sg-platform-report-graph-sub-title text-center"> Food Restrictions </h1>
              {
                attendeeCount === 0 ?
                <div style={{textAlign: "center", margin: "20px 0px"}}>No registrants yet</div>
                :
                <PieChart colors={this.colorPalatte()} legend={false} data={this.getFoodRestrictions()} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReports() {
    const { zipfile } = this.props;
    const reports = [
      {name: "Master Report", url:"/admin/reports/registered_report"},
      {name: "Activity Report", url:"/admin/reports/activities_report"},
      {name: "Hotel Report", url:"/admin/reports/hotel_report"},
      {name: "Consumables Report", url:"/admin/reports/consumables_report"},
      {name: "Flight Report", url:"/admin/reports/travel_report"},
      {name: "Photo Report", url:"/admin/reports/photo_report"},
      {name: "Photo Download", url:zipfile}
      // {name: "Photo Download", url:"/zips/pclub24_image_uploads.zip"}
    ]
    const renderedReports = reports.map(x=>(
      <tr>
        <td>
          <span className="sg-admin-user-panel-reports-table-report-name">{x.name}</span>
        </td>
        <td >
          <a className="sg-admin-user-panel-reports-table-download" href={x.url}> Download </a>
        </td>
      </tr>
    ));
    return (
      <>
        <h1 className="sg-platform-report-graph-title" style={{marginTop: "20px", marginBottom: 0}}> Download reports </h1>
        <div className="sg-platform-reports-section-subtitle">
          Updated in real time
        </div>
          <table className="sg-admin-user-panel-table">
            {renderedReports}
          </table>
      </>
    )
  }

  render() {
    return this.renderFrame();
  }
}

export default PlatformReports;
// export default UsersDashboard;
