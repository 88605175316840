import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Cookies from "universal-cookie";
import Modal from "react-modal";
import { dataURItoBlob } from "@utility/FileUtils";
import Cropper from "react-cropper";

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzoneText: "",
      file: '',
      fileDropped: false,
      imagePreviewUrl: this.props.setImage,
      openModal: false,
      cropResult: '',
      loading: false
    };
    this.onDrop = this.onDrop.bind(this);
    this.exitModal = this.exitModal.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  formUrl() {
    return "/tiles";
  }

  regPhotoUpload(file) {
    const { setFiles } = this.props;
    const { formikProps, fieldValue } = this.props;
    const { user } = formikProps.values;

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    var form_data = new FormData();
    form_data.append('picture', file);

    fetch('/registration/photo_upload', {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        user[fieldValue] = json.response;
        this.setState({loading: false});
        formikProps.setFieldValue('user', user);
      } else {
        user[fieldValue] = '';
        formikProps.setFieldValue('user', user);
        this.setState({
          dropzoneText: " ",
          file: null,
          fileDropped: false,
          imagePreviewUrl: null,
          openModal: false,
          loading: false
        });
      }
    });
  }

  onDrop(acceptedFiles) {
    const { formikProps, fieldValue } = this.props;
    const { user } = formikProps.values;
    if (acceptedFiles.length > 0) {
      // if (acceptedFiles[0].size > 3e+6) {
      //   this.setState({
      //     dropzoneText: "This file is too large."
      //   });
      // } else {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        this.setState({
          dropzoneText: "",
          file: acceptedFiles[0],
          fileDropped: true,
          imagePreviewUrl: reader.result,
          openModal: true,
          loading: true
        });
      }
      this.regPhotoUpload(acceptedFiles[0]);

      // user[fieldValue] = acceptedFiles[0];
      // formikProps.setFieldValue('user', user);
    } else {
      this.setState({
        dropzoneText: "This file type is not supported."
      });
    }
  }

  exitModal() {
    this.setState({ openModal: false });
  }

  cropImage() {
    const { name, setFiles } = this.props;
    const { file, tagList, usingTags } = this.state;
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    let dataURL = this.cropper.getCroppedCanvas().toDataURL()
    this.setState({
      cropResult: dataURL,
      openModal: false
    });
    let tags = tagList;
    if (!usingTags) {
      tags = [];
    }
    let blob = dataURItoBlob(dataURL, file.name);
    setFiles(name, blob, dataURL, tags);
  }

  setDropzoneBeforeDrop() {
    const { setImage, profile_image } = this.props;
    const { dropzoneText, imagePreviewUrl } = this.state;
    if (setImage && imagePreviewUrl) {
      return (
        <>
          <img src={imagePreviewUrl} className="drop-section-preview"/>
        </>
      );
    }
    return (
      <>
        {/* <div className="dropzone-text">{dropzoneText}</div> */}
      </>
    );
  }

  showPreviews() {
    const { editCrop } = this.props;
    const { file, imagePreviewUrl, loading } = this.state;
    if (loading) {
      return (
        <>
          <div style={{backgroundImage: `url(${imagePreviewUrl})`}} className="drop-section-preview">
            <div className="lds-ring" style={{marginTop: "15px"}}><div></div><div></div><div></div><div></div></div>
          </div>
        </>  
      );    
    }
    return (
      <>
        <div style={{backgroundImage: `url(${imagePreviewUrl})`}} className="drop-section-preview"/>
      </>
    );
  }

  editCrop(e) {
    e.cancelBubble = true;
    e.stopPropagation();
    this.onDrop([this.state.file]);
  }

  renderCropper() {
    return (
      <Cropper
        src={this.state.imagePreviewUrl}
        style={{ height: "50vh", width: "100%",maxHeight:"50vh", maxWidth: "90vw", minWidth:"40vw" }}
        aspectRatio={this.props.freeRatio ? null : 16 / 16}
        preview=".img-preview"
        guides={true}
        ref={cropper => {
          this.cropper = cropper;
        }}
        viewMode={1}
        dragMode="move"
        autoCropArea={1}
        cropBoxMovable={false}
      />
    );
  }

  renderModal() {
    return (
        <div className="sg-modal-cropper">
          <h4>Crop Image</h4>
          {this.renderCropper()}
          <div className="clear-div" />
          <button className="registration-next-button" onClick={this.cropImage}>Upload</button>
          <div className="log-in-close" onClick={this.exitModal}>
            <div className="video-overlay-close-button" style={{paddingTop: "10px"}}> ✕ </div>
          </div>
          <div className="clear-div" />
        </div>
    );
  }

  removeImage(e) {
    const { setFiles } = this.props;
    e.stopPropagation();
    this.setState({
      dropzoneText: "",
      file: null,
      fileDropped: false,
      imagePreviewUrl: null,
      openModal: false
    });
    setFiles(null, null, null, null);
  }

  renderChangeRemoveOptions() {
    const { imagePreviewUrl } = this.state;
    if(imagePreviewUrl) {
      return (
        <div className="change-remove-options">
          <div style={{display: "inline-block"}} onClick={this.removeImage}>Remove Photo</div>
        </div>
      )
    }
    return '';
  }

  render() {
    const { showPreviews, fullWidth } = this.props;
    const { imagePreviewUrl, cropResult, file, fileDropped, dropzoneText, openModal } = this.state;
    return (
      <>
        <Dropzone onDrop={this.onDrop} name="hero_image[image]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className={fullWidth ? "drop-section-tiles-full" : "drop-section-tiles"}>
                <input {...getInputProps()} id="item-form-attachment-dropzone-input"/>
                {fileDropped && showPreviews ? (
                  this.showPreviews()
                ) : (
                  this.setDropzoneBeforeDrop()
                )}
                {this.props.showOptions ? this.renderChangeRemoveOptions() :""}
                {
                  dropzoneText !== "" ?
                  <div className="dropzone-text">{dropzoneText}</div>
                  :
                  <></>
                }
              </div>
            );
          }}
        </Dropzone>
      </>
    );
   }
}

export default DropzoneComponent;
