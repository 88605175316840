import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";

class SurveyModal extends React.Component {
  constructor(props) {
    super(props);
    const { video } = props;
    this.state = {
      modalOpen: false,
      radioValue: null,
      question1Value: null,
      question2Value: null,
      question3Value: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  closeModal() {
    const { modalOpen } = this.state;
    const {onOpenCallback, onCloseCallback} = this.props;
    if (onCloseCallback) {
      onCloseCallback();
    }
    this.setState({
      modalOpen: false
    });
  }

  openModal() {
    const { modalOpen } = this.state;
    const {onOpenCallback, onCloseCallback, completed} = this.props;
    if(!completed) {
      if (onOpenCallback) {
        onOpenCallback();
      }
      this.setState({
        modalOpen: true
      });
    }
  }

  renderRadioBox(value, label, key){
    return (
      <span key={`key-${value}-${label}`} className="radio-question">
        <input
          onClick={()=>this.updateValue(key, value)}
          className="radio_buttons optional radio-scale-input"
          checked={value==this.state[key]}
          type="radio"
          value={value==this.state[key]}
          name={`checkbox-${key}-id`}
        />
        <label
          onClick={()=>this.updateValue(key, value)}
          className="collection_radio_buttons"
          htmlFor="survey_response_question_responses_attributes_1_response_t"
        >
          {label}
        </label>
      </span>
    )
  }

  updateValue(key, value){
    const newState = this.state;
    newState[key] = value;
    this.setState({
      newState
    })
  }

  renderSpeakers(session) {
    if(!session) {
      return "";
    }
    return (
      <div className="survey-speaker">
        {session.speakers ? session.speakers.map((x,index,t)=>
          <span key={`modal-speaker-${x.id}`}>
            {x.name_first} {x.name_last}, {x.job_title}, {x.company}&nbsp;
            <br />
          </span>

        ): ""}
      </div>
    )
  }

  renderVideoModal() {
    const { session } = this.props;
    return (
      <div className="sumo-video-survey-modal-inner">
        <div className="sg-survey-modal-title-blurb">
          We hope you enjoyed the session.
          <br />
          Please help us improve the experience by filling out this brief survey.
        </div>
        <div className="sg-survey-modal-session-type">
          {session.session_type}
        </div>
        <div className="survey-title">
          {session.title}
        </div>
        {this.renderSpeakers(session)}
        <div className="question">
          <div className="input radio_buttons optional survey_response_question_responses_response">
            <br/>
            <br/>
            <label className="radio_buttons optional radio-scale-label">This session was a good use of my time.</label>
            <br/>
            {['Extremely useful','Very useful','Useful','Somewhat useful','Not so useful'].map(x=> this.renderRadioBox(x,x, "question1Value"))}
          </div>
          <div className="input radio_buttons optional survey_response_question_responses_response">
            <br/>
            <br/>
            <label className="radio_buttons optional radio-scale-label">How would you rate the presenter’s knowledge of the material?</label>
            <br/>
            {['Excellent','Very good','Good','Fair','Poor'].map(x=> this.renderRadioBox(x,x, "question2Value"))}
          </div>
          <div>
            <br />
            <br />
            <label className="radio_buttons optional radio-scale-label">Do you have any other comments, questions, or concerns?</label>
            <textarea onChange={(e)=>this.updateValue("question3Value", e.target.value)}type="textarea" style={{minWidth: "100%", maxWidth:"100%", minHeight: "100px"}}/>
          </div>
          <div>
            <button disabled={this.isSaveDisabled()}className="sumo-submit-button" onClick={this.submitForm}> Submit </button>
            <a href="#" className="sumo-cancel-button" onClick={this.closeModal}>
              Maybe later
            </a>
          </div>
        </div>
      </div>
    )
  }

  submitForm() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { session, completeSurveyCallback } = this.props;
    const { question1Value, question2Value, question3Value} = this.state;
    if(completeSurveyCallback) {
      completeSurveyCallback(session);
    }
    this.setState({
      modalOpen: false,
    })
    fetch('/surveys', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(
        {
          session_uuid: session.uuid,
          body: {
            answer_1: question1Value,
            answer_2: question2Value,
            comments: question3Value
          }
        }
      ),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      this.closeModal();
      if (json.error == null) {

      } else {

      }
    });
  }

  isSaveDisabled() {
    const { question1Value, question2Value, question3Value} = this.state;
    return !(question1Value || question2Value || question3Value)
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children, inlineBlock, session } = this.props;
    return (
      <>
        <div className={outerClassNames} style={{cursor:"pointer", display: inlineBlock ? "inline-block" : null}} onClick={() => this.openModal()} >
          {children}
        </div>
        <Modal
          className="sg-channel-survey-modal white-background disable-scrollbars"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.closeModal}
          contentLabel=""
          key={`survey-${session.uuid}`}
        >
          <div className="sg-agenda-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          {this.renderVideoModal()}
        </Modal>
      </>
    );
  }
}

export default SurveyModal;
