import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import DropzoneComponent from "./DropzoneComponent"

class ProfilePicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      projectImage: null,
      previewImage: this.props.previewImage,
      changed: false,
    }
    this.setFiles = this.setFiles.bind(this);
  }

  setFiles(name, file, preview, tagList) {
    if(this.props.onDirty) {
      this.props.onDirty(file, preview, preview, tagList);
    }
    this.sendFiles(file);
    this.setState({
      projectImage: file,
      previewImage: preview
    });
  }

  sendFiles(projectImage) {
    if(this.props.dontFetch) {
      return;
    }
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    form_data.append('user[profile_image]', projectImage);
    this.props.onDirtyPicture(true);
    fetch('/registration/update_profile_image', {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {
        console.log(json.error);
      }
    });
  }

  render() {
    const { user, formikProps, fieldValue, erroring } = this.props;
    const { changed, previewImage} = this.state;
    return (
      <div className={`reg-profile-icon square ${erroring ? 'profile-error' : ''}`}>
        <div className={changed ? 'sg-profile-picture-changed' : ''}></div>
        <div className="reg-profile-overlay">
          <div className="icon-div">
            {/* {!(previewImage) ? (<img className="reg-profile-image" src="/images/reg-profile.svg" />):""} */}
            {
              !(previewImage) ?
              (
                <div style={{position: 'absolute', width: '100%', height: '100%'}}>
                  <div style={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', color: '#0092D2'}}>
                    Upload<br />Photo
                  </div>
                </div>
              )
              :
              ""
            }
            <DropzoneComponent customUploadPhotoText="Add profile image" formikProps={formikProps} fieldValue={fieldValue} showOptions={true} name="drawing" setFiles={this.setFiles} showPreviews={true} editCrop={false} setImage={this.props.previewImage} />
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePicture;
