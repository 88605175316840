import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes, addHours, addDays } from 'date-fns'
class RegistrationReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  formatBirthday(field) {
    try {
      return format(addDays(Date.parse(field),1),"MM/dd/yyyy")
    }
    catch(e) {
      console.log(e);
      return field;
    }
  }

  renderContactInformation(formikProps, prefix="") {
    const user = formikProps.values.user;
    // {name:"Birthdate", value: this.formatBirthday(user[prefix+"birthdate"])},
    // {name:"Birthdate", value: this.formatBirthday(user["guestbirthdate"])},
    let fields = [
      {name:"First/Last Name", value: `${user[prefix+"name_first"]} ${user[prefix+"name_last"]}`, customSize: 'col-xs-12'},
      {name:"Cell phone number", value: user[prefix+"cellphonenumber"]},
      {name:"Email", value: user[prefix+"email"]},
      {name:"Address", value: prefix && !user['guestaddressdifferent'] ? "Same" : this.generateAddressString(formikProps, prefix)},
      {name:"Emergency Contact", value: prefix && !user['guestaddressdifferent'] ? "Same" : (<>{user[prefix+'ecname']}<br/>{user[prefix+'ecnumber']}</>)},
    ]

    if(prefix) {
      fields = [
        {name:"First/Last Name", value: `${user["guestfirstname"]} ${user["guestlastname"]}`, customSize: 'col-xs-12'},
        {name:"Cell phone number", value: user["guestcellphonenumber"]},
        {name:"Email", value: user["guestemailaddress"]},
        {name:"Address", value: prefix && !user['guestaddressdifferent'] ? "Same" : this.generateAddressString(formikProps, prefix)},
        {name:"Emergency Contact", value: prefix && !user['guestaddressdifferent'] ? "Same" : (<>{user['guestemergencycontactname']}<br/>{user['guestemergencycontactnumber']}</>)},
      ]
    }

    if (!prefix && !user.usaddress) {
      fields.push({name: "Passport number", value: user["passportnumber"]})
      fields.push({name: "Passport country", value: user["passportcountry"]})
      fields.push({name: "Passport date of issue", value: user["passportissued"]})
      fields.push({name: "Passport date of expiration", value: user["passportexpiration"]})
    }
    if (prefix && !user.guestusaddress) {
      fields.push({name: "Passport number", value: user["guestpassportnumber"]})
      fields.push({name: "Passport country", value: user["guestpassportcountry"]})
      fields.push({name: "Passport date of issue", value: user["guestpassportissued"]})
      fields.push({name: "Passport date of expiration", value: user["guestpassportexpiration"]})
    }

    return this.renderFieldSet(fields);
  }

  renderFieldSet(fields) {
    const { formikProps } = this.props;
    const user = formikProps.values.user;

    if(!user.bringingguest) {
      fields = fields.filter(x=>
        !x.guestOnly
      )
    }

    const renderedFields = fields.map(field => (
      <div className={(field.customSize || "col-xs-12 col-sm-6")+" sg-pc-review-fields-field-container"} key={`field-${field.name}`}>
        <div className="answer-label">
          {field.name}
        </div>
        <div className="answer-value">
          {field.value}
        </div>
      </div>
    ))

    return (
      <div className="row sg-pc-review-row">
        {renderedFields}
      </div>
    )
  }

  generateAddressString(formikProps, prefix) {
    const { values } = formikProps;
    const { user } = values;
    if(prefix) {
      return (
        <>
          {user['guestaddress1']}
          <br/>
          {user['guestaddress2'] ? (
            <>{user['guestaddress2']} <br/></>
        ):""}
          {[user['guestaddresscity'],user['guestaddressstate'],user['guestpostalcode'],user['guestaddresscountry']].filter(x=> x).join(", ")}
        </>
      )
    }
    return (
      <>
        {user['override_work_address_street_1']}
        <br/>
        {user['override_work_address_street_2'] ? (
          <>{user['override_work_address_street_2']} <br/></>
      ):""}
        {[user['override_work_address_city'],user['override_work_address_state'],user['override_work_address_postcode'],user['override_work_address_country']].filter(x=> x).join(", ")}
      </>
    )
  }

  renderTripInformation(formikProps, prefix) {
    const user = formikProps.values.user;
    // {name:"# of guests", value: "1", guestOnly: true},

    let fields = [
      {name:"Check-in date", value: user["arrivaldate"]},
      {name:"Check-out date", value: user["departuredate"]},
      {name:"Room preference (not guaranteed)", value: user["roompreference"]},
      {name: "", value: ""},

      {name:"Flight Arrival Date", value: user["arrivaldateinbound"]},
      {name:"Flight Arrival Time", value: user["arrivaltimeinbound"]},
      {name:"Flight Airline Inbound", value: user["airlineinbound"]},
      {name:"Flight number Inbound", value: user["flightnumberinbound"]},

      {name:"Flight Departure Date", value: user["departuredateoutbound"]},
      {name:"Flight Departure Time", value: user["departuretimeoutbound"]},
      {name:"Flight Airline Outbound", value: user["airlineoutbound"]},
      {name:"Flight number Outbound", value: user["flightnumberoutbound"]},

    ]

    if(prefix) {
      fields = [
        {name:"Flight Arrival Date", value: user[`arrivaldateinbound${prefix}`]},
        {name:"Flight Arrival Time", value: user[`arrivaltimeinbound${prefix}`]},
        {name:"Flight Airline Inbound", value: user[`airlineinbound${prefix}`]},
        {name:"Flight Number Inbound", value: user[`flightnumberinbound${prefix}`]},

        {name:"Flight Departure Date", value: user[`departuredateoutbound${prefix}`]},
        {name:"Flight Departure Time", value: user[`departuretimeoutbound${prefix}`]},
        {name:"Flight Airline Outbound", value: user[`airlineoutbound${prefix}`]},
        {name:"Flight Number Outbound", value: user[`flightnumberoutbound${prefix}`]},
      ]
    }

    return this.renderFieldSet(fields);
  }

  renderOuterwear(formikProps) {
    const user = formikProps.values.user;
    const fields = [
      {name:"Jacket/Shirt size - Self", value: user["jacketshirtsizeself"]},
      {name:"Jacket/Shirt size - Guest", value: user["jacketshirtsizeguest"], guestOnly: true},
      {name:"Shoe size - Self", value: user["shoesizeself"]},
      {name:"Shoe size - Guest", value: user["shoesizeguest"], guestOnly: true},
    ]

    return this.renderFieldSet(fields);
  }

  renderSnacks(formikProps) {
    const user = formikProps.values.user;
    let fields = [
      {name:"Food restrictions - Self",flabel:"foodrestrictions", value: (user["foodrestrictions"]||[]).filter(item => item).join(", ")},
      {name:"Food restrictions - Guest",flabel:"guestfoodrestrictions", value: (user["guestfoodrestrictions"]||[]).filter(item => item).join(", "), guestOnly: true},
      {name:"Is there anything else you would like to share? Or have any preferences or questions?",customSize:"col-xs-12", value: user["otherquestions"]}
    ];
    fields = fields.map(x=> {
      if(x.flabel && user[x.flabel+"other"]) {
        x.value = x.value+`, ${user[x.flabel+"other"]}`
      }
      return x
    })
    return this.renderFieldSet(fields);
  }

  renderGroupActivity(formikProps) {
    const user = formikProps.values.user;
    if(user.attendinggroupexcursion == true) {
      const fieldSet = [
        {name:"Group Activity", value: <>
          Group Catamaran Trip - Banderas Bay
          <br/>
          May 15, 9:00am - 2:30 pm
        </>},
        {name:"People attending", value: user['groupexcursionpeoplecount'], guestOnly: true},
      ]
      return this.renderFieldSet(fieldSet);
    }
  }

  renderActivities(formikProps) {
    const user = formikProps.values.user;
    const { activities } = this.props;
    const chosenActivities = user.chosenactivities || [];
    return chosenActivities.map((chosenActivity,index) => {
      const activity = activities.find(x=> chosenActivity == x.answers.id) || {};

      let fieldSet = [
        {name:"Individual Activity", value: <>{activity.answers.name}<br/>{activity.answers.date.replace(" |",",")}</>},
        {name:"People attending", value: user[`selectedactivitypersoncount${index+1}`], guestOnly: true},
      ]

      // fieldSet = fieldSet.concat(activity.fields.map(field => {
      //   return {name: field.name, value: user[field.id]}
      // }));

      return this.renderFieldSet(fieldSet);
    })
  }

  render() {
    const { formikProps } = this.props;
    const { values } = formikProps;
    return (
      <>
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
            Your contact information
          </div>
        </div>
        {this.renderContactInformation(formikProps,"")}
        <div className="row">
          <div className="sg-presidents-club-reg-text-section"></div>
        </div>
        {values.user.bringingguest ? (
          <>
          <div className="row">
            <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
              Your guest’s contact information
            </div>
          </div>
          {this.renderContactInformation(formikProps,"guest_")}
          <div className="row">
            <div className="sg-presidents-club-reg-text-section"></div>
          </div>
          </>
        ):""}
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
            Hotel and Travel
          </div>
        </div>
        {this.renderTripInformation(formikProps,"")}
        <div className="row">
          <div className="sg-presidents-club-reg-text-section"></div>
        </div>
        {values.user.bringingguest && values.user.flightinfodifferent ? (
          <>
          <div className="row">
            <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
              Travel: Guest
            </div>
          </div>
          {this.renderTripInformation(formikProps,"guest")}
          <div className="row">
            <div className="sg-presidents-club-reg-text-section"></div>
          </div>
          </>
        ):""}
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
            Activities
          </div>
        </div>
        {this.renderGroupActivity(formikProps)}
        {this.renderActivities(formikProps)}
        <div className="row">
          <div className="sg-presidents-club-reg-text-section"></div>
        </div>
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
            Outerwear
          </div>
        </div>
        {this.renderOuterwear(formikProps)}
        <div className="row">
          <div className="sg-presidents-club-reg-text-section"></div>
        </div>
        <div className="row">
          <div className="col-xs-12 sg-pc-section-header sg-pc-section-header-review">
            Food Restrictions
          </div>
        </div>
        {this.renderSnacks(formikProps)}
        <div className="row">
          <div className="col-xs-12" style={{color: "#21B9CC", fontSize: "14px", fontFamily: "Grotesque Medium"}}>
            <br/>
            <br/>
            After clicking submit, you’ll receive an email confirmation with your selections.
          </div>
        </div>
      </>
    )
  }
}

export default RegistrationReviewForm;
