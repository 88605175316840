import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils"

class BackupSettingsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: props.settings,
      error: null,
    }
    this.changeSettingValue = this.changeSettingValue.bind(this);
  }

  renderSettings() {
    const { settings } = this.state;
    return (
      <>
      <tr>
        <td>
          Use Webex for Meetings
        </td>
        <td>
          Turning this on will switch all zoom meetings to webex meetings
        </td>
        <td>
          <b>{settings.webex_meetings ? "ON" : "OFF"}</b>
        </td>
        <td>
          {this.renderBooleanOptions('webex_meetings')}
        </td>
      </tr>
      <tr>
        <td>
          Use Whitelist
        </td>
        <td>
          Turning this on will activate the whitelist
        </td>
        <td>
          <b>{settings.activate_whitelist ? "ON" : "OFF"}</b>
        </td>
        <td>
          {this.renderBooleanOptions('activate_whitelist')}
        </td>
      </tr>
      </>
    );
  }

  renderBooleanOptions(label) {
    return (
      <>
        <a className="sg-admin-backup-options-boolean-button" href="#" onClick={()=>this.changeSettingValue(label, true)}>
          TURN ON
        </a>
        <a className="sg-admin-backup-options-boolean-button" href="#" onClick={()=>this.changeSettingValue(label, false)}>
          TURN OFF
        </a>
      </>
    )
  }

  changeSettingValue(label, value) {
    if(!(prompt(`Are you absolutely sure you want to change ${label} to: ${value}? Type 'jaguar' to continue`)=="jaguar")) {
      return;
    }

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    const body = {
      backup_settings:{}
    };
    body['backup_settings'][label] = value;
    fetch('/admin/backup_settings', {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": 'application/json',
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.setState({
          settings: json.settings,
          error: null,
        })
        alert(`${label} was successfully changed to: ${value}`)
      } else {
        alert(json.error);
        this.setState({
          error: json.error
        })
      }
    });
  }

  renderError(){
    const { error } = this.state;
    if(error) {
      return (
        <div className="sg-admin-backup-settings-error">
        An Error Occured:
        <br />
        {error}
        </div>
      )
    }
  }

  render() {
    return (
      <>
      {this.renderError()}
      <table className="sg-admin-user-panel-table" style={{marginBottom: "800px"}}>
        <tr>
          <th>
            Setting Name
          </th>
          <th>
            Description
          </th>
          <th>
            Current Value
          </th>
          <th>
            Options
          </th>
        </tr>
        {this.renderSettings()}
      </table>
      </>
    );
  }
}

export default BackupSettingsDashboard;
