import React from "react";
import FormBuilder from "../forms/FormBuilder";

class RegistrationFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('reg submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      // {key: '*|EMAIL|*', value: participant.email }
    ]
  }

  render() {
    const { ticket_info, participant, form, current_user } = this.props;
    return (
      <FormBuilder
        current_user={current_user}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/registration'}
        submitCallback={this.callback}
        type={'registration'}
      />
    );
  }
}

export default RegistrationFormWrapper;
