import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import Modal from "react-modal";
import QuestionPicture from "./QuestionPicture"
import {getRegionSelectOptions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes}  from "./CountryCodes"
import DropzoneComponent from "./DropzoneComponent"
import ProfilePicture from "./ProfilePicture"

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      agreeToTerms: false,
      projectImage: null,
      previewImage: null,
      dirty: false,
      topicModalOpen: false,
      updatedSuccess: false,
      loading: false,
      user: props.user,
      field_identifiers: props.field_identifiers,
      questionPicture: null,
      questionPicturePreview: props.question_picture
    }
    this.setFiles = this.setFiles.bind(this);
    this.onDirtyPicture = this.onDirtyPicture.bind(this);
    this.setFilesQuestion = this.setFilesQuestion.bind(this);

  }

  setFiles(name, file, preview, tagList) {
    this.setState({
      projectImage: file,
      previewImage: preview
    });
  }


  setFilesQuestion(name, file, preview, tagList) {
    this.setState({
      questionPicture: file,
      dirty: true,
    });
  }

  onDirtyPicture() {
    this.setState({
      dirty: true
    })
  }

  isEmailPersonal(email) {
    return false
  }


  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  renderPageOne(formikProps) {
    const { pageOneError, dirty, pageThreeError, updatedSuccess, loading, questionPicturePreview } = this.state;
    return (
      <div className={`registration-page-one`}>
        {this.props.success ? (
          <div className="registration-profile-success">
            Your changes have been saved
          </div>
        ): ""}
        <div className="row">
          <div className="col-xs-12 page-three-bold-text text-center" style={{padding: "40px 20px 20px 20px"}}>
            Profile image.
          </div>
          <div className="col-xs-12 text-center">
            <div className="reg-profile-icon square" style={{margin: 'auto'}}>
              <div className="reg-profile-overlay">
                <div className="icon-div">
                  <img className="reg-profile-image" src="/registration/reg-profile.svg" />
                  <ProfilePicture onDirtyPicture={this.onDirtyPicture} previewImage={this.props.profile_image} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 sg-registration-subheader-blue">
          </div>
          <div className="col-xs-12">
            <label className="required"> First name: </label>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12">
            <label className="required"> Last name: </label>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12">
            <label className="required"> Title: </label>
            {this.props.speakers && this.props.speakers.length > 0 ?
              (
                <input value={formikProps.values.user.job_title} className="form-control" disabled={true}/>
              )
              :
              (
                this.renderField('job_title',formikProps)
              )
            }
          </div>
          <div className="col-xs-12">
            <label className="required"> Email Address (can not be changed): </label>
            <input value={formikProps.values.user.email} className="form-control" disabled={true}/>
          </div>
          <div className="col-xs-12">
            <label className="required"> Region </label>
            {this.renderSelectField("address_country", formikProps, getRegionSelectOptions())}
          </div>
          <div className="col-xs-12">
            <label className="required"> Job Function </label>
            {this.renderSelectField("job_function", formikProps, getJobTypes())}
          </div>
          <div className="col-xs-12">
            <label className="required"> Time Zone </label><lable className='sg-profile-time-zone-label'> (program offerings will display in this time zone, can be changed later) </lable>
            {this.renderSelectField("timezone_utc", formikProps, getTimezoneOptions())}
          </div>
          <div className="col-xs-12 sg-registration-subheader-blue">
          </div>
          <div className="col-xs-12">
            <label className="">In one word, how would you say you’ve persevered during the pandemic? </label>
            {this.renderField('pandemic_question',formikProps, this.customOneWordValidation(formikProps.values.user['pandemic_question']), {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">If you had to pick a “theme song” that best represents YOU, what song would that be and who is the artist?</label>
            {this.renderField('theme_song',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">Please attach a picture of you, you and your family, your pet, etc. doing something you likely would not have done if it weren’t for COVID lockdowns.</label>
            <div className="sg-reg-picture-2 reg-profile-icon square" style={{background: "white", border: "1px solid #D4D8DB", maxWidth: "200px"}}>
              <div className="reg-profile-overlay">
                <div className="icon-div" style={{width: "100%", height: "100%"}}>
                  <img className="reg-profile-image" src="/registration/images/arrow.svg" style={{padding: "50px", marginBottom: "20px"}}/>
                  <QuestionPicture onDirty={this.setFilesQuestion} previewImage={questionPicturePreview} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 sg-registration-subheader-blue">
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="registration-field-error">
              {pageThreeError}
            </div>
            <button disabled={loading || !(dirty || this.props.pictureDirty)} className={loading || !(dirty || this.props.pictureDirty) ? 'disabled-registration-next-button' : `registration-next-button`} type="button" onClick={() => this.submitFormButtonOnClick(formikProps)}>
              Save
            </button>

            {
              updatedSuccess ? (
                <div className="sg-registration-profile-form-updated-container">
                  <br/>
                  Your changes have been saved
                  <br/>

                  <a href="/">
                    Click here to return to your dashboard
                  </a>
                </div>
              ):(
                <a href="/">
                  <button  className={'sg-registration-cancel-button'} type="button">
                    Cancel
                  </button>
                </a>
              )
            }
            <br />
            <br />
              <br />
              <br />
          </div>

        </div>
      </div>
    )
  }


  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (!formikProps.values.user[name] || formikProps.values.user[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field
        onChange={(e) => {
            const { user } = formikProps.values;
            user[name] = e.target.value
            formikProps.setFieldValue('user', user);
            this.setState({
              dirty: true,
            });
          }
        }
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`user[${name}]`}/>
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false) {
    const { checkError } = this.state;
    const erroring = checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    const defaultObject = {label: formikProps.values['user'][name], value: formikProps.values['user'][name]}
    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{label: formikProps.values['user'][name], value: formikProps.values['user'][name]}}
        value={options ? options.find(option => option.value === formikProps.values['user'][name]) || {label: formikProps.values['user'][name], value: formikProps.values['user'][name]} : ""}
        filterOption={this.customFilter}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
          this.setState({
            dirty: true,
          })
        }}
      />
    )
  }

  customOneWordValidation(answer) {
    return answer && answer.indexOf(' ') > -1;
  }

  customFilter(option, searchText) {
    const ones =  option.label.toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }


  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }



  areFieldsValid(formikProps) {
    const fieldList = [
      "name_first",
      "name_last",
      "job_title",
      "address_country",
      "timezone_utc",
      "job_function"
    ]
    const { values } = formikProps;
    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    const isEmailValid = !this.isEmailPersonal(values["user"]["email"]);
    return validFields.length == fieldList.length && isEmailValid;
  }

  submitFormButtonOnClick(formikProps) {
    if(!this.areFieldsValid(formikProps)){
      this.setState({
        pageThreeError: "Please make sure all fields are input correctly.",
        checkError: true
      })
    }
    else if(formikProps.values["user"]['pandemic_question'] && formikProps.values["user"]['pandemic_question'].indexOf(" ") > -1) {
      this.setState({
        pageThreeError: "Please make sure your answer to 'In one word, how would you say you’ve persevered during the pandemic?' is only one word and contains no spaces.",
        checkError: true
      })
    }
    else {
      this.setState({
        pageThreeError: null,
        checkError: true,
      })
      formikProps.handleSubmit()
    }
  }

  getFieldId(field_name, props = null) {
    const { user, field_identifiers } = props ? props : this.state;
    const field = field_identifiers[field_name];
    return field ? field['id'] : null;
  }

  isFieldData(field_name, props = null){
    const { user, field_identifiers } = props ? props : this.state;
    const field = field_identifiers[field_name];
    return field ? !!field['data'] : false;
  }

  getFieldLabel(field_name, props = null) {
    const { user, field_identifiers } = props ? props : this.state;
    const field = field_identifiers[field_name];
    return field ? field['label'] : null;
  }

  getFieldDataLabel(field_name, props = null) {
    const { user, field_identifiers } = props ? props : this.state;
    const field = field_identifiers[field_name];
    return field ? field['data_label'] : null;
  }

  getFieldValue(field_name, props = null) {
    const { user } = props ? props : this.state;
    if(this.isFieldData(field_name, props)){
      return user['data'][this.getFieldDataLabel(field_name, props)]
    }
    return user['registration_answers'][this.getFieldId(field_name, props)]
  }


  render() {
    const { user } = this.state;
    return (
      <div className="registration-form">
        <Formik
          initialValues={{
            user: {
              salutation: user ? this.getFieldValue('salutation') : "",
              email: user ? user.email : "",
              name_first: user ? user.name_first : "",
              name_last: user ? user.name_last : "",
              company: user ? user.company : "",
              job_title: user ? user.job_title : "",
              address_country: user ? user.address_country : "",
              registration_type: user ? this.getFieldValue('registration_type') : "",
              job_function: user ? this.getFieldValue('job_function') : "",
              timezone_utc: user ? this.getFieldValue('timezone_utc') : "",
              pandemic_question: user ? this.getFieldValue('pandemic_question') : "",
              theme_song: user ? this.getFieldValue('theme_song') : "",
            },
          }}

          onSubmit={(values, actions) => {
            const { projectImage, questionPicture } = this.state;
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");
            this.setState({
              loading: true,
            })
            var form_data = new FormData();
            const timezone = getTimezoneInfo().find(x=> x.value==values['user']['timezone_utc'])
            if(timezone) {
              form_data.append("user[timezone_offset]",  timezone.offset);
            }
            for ( var key in values['user'] ) {
                if(key == 'department' && values['user'][key]=="Other"){
                  form_data.append(`user[${key}]`, values['other-department']);
                }else {
                  form_data.append(`user[${key}]`, values['user'][key]);
                }
            }
            if(questionPicture) {
              form_data.append('user[question_picture]', questionPicture);
            }
            fetch('/profile', {
              method: 'POST',
              redirect: "manual",
              body: form_data,
              headers: {
                "X-CSRF-Token": token,
              }
            })
              .then(response => {
                return response.json();
              })
              .then(json => {
                if (json.error == null) {
                  this.setState({
                    updatedSuccess: true,
                    loading: false,
                    dirty: false,
                  },
                  );
                } else {
                  this.setState({
                    pageThreeError: json.error,
                    loading: false
                  })
                }
              });
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form">
              <div className="registration-profile-sub-header" style={{textAlign: 'center', position: "relative"}}>
                <div className="registration-page-one" style={{textAlign: 'left',display:'inline-block'}}>
                  <div className="registration-profile-name">
                    My Profile
                  </div>
                </div>
              </div>
              {this.renderPageOne(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default ProfileForm;
