import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

class ActivityMoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moreInfoOpen: false
    }
    this.toggleMoreInfo = this.toggleMoreInfo.bind(this);
  }

  toggleMoreInfo() {
    const { moreInfoOpen } = this.state;
    this.setState({ moreInfoOpen: !moreInfoOpen });
  }

  renderMoreInfo() {
    const { moreInfoOpen } = this.state;
    const { activity } = this.props;
    if (moreInfoOpen) {
      return (
        <>
          <div className="activity-info-spacer" />
          <div dangerouslySetInnerHTML={{__html: activity.answers.more_info}} />
        </>
      );
    }
    return <></>;
  }

  render() {
    const { moreInfoOpen } = this.state;
    return (
      <div className="reg-more-info">
        <div className="reg-more-info-button" onClick={this.toggleMoreInfo}>
          <img className={moreInfoOpen ? "reg-more-info-button-img-open" : "reg-more-info-button-img-closed"} src="/images/ico-more-info.svg" />
          More info
        </div>
        {this.renderMoreInfo()}
      </div>
    );
  }
}

export default ActivityMoreInfo;
