export function nonAlcoholicBeverageOptions() {
  return [
    "Coffee",
    "Tea",
    "Water Flat",
    "Water Sparkling",
    "Soda",
    "Juice",
    "Other"
  ].map(x=> {return {value: x, label: x}})
}

export function alcoholicBeverageOptions() {
  return [
    "Red Wine",
    "White Wine",
    "Beer",
    "Vodka",
    "Gin",
    "Scotch",
    "Bourbon",
    "Rye",
    "Tequila",
    "Rum",
    "Other"
  ].map(x=> {return {value: x, label: x}})
}

export function mixerBeverageOptions() {
  return [
    "Club Soda",
    "Tonic",
    "Diet Tonic",
    "Cranberry Juice",
    "Margarita Mix",
    "Mojito Mix",
    "Other"
  ].map(x=> {return {value: x, label: x}})
}

export function snacksOptions() {
  return [
    "Fruit",
    "Nuts",
    "Chips",
    "Candy",
    "Cheese",
    "Yogurt",
    "Other"
  ].map(x=> {return {value: x, label: x}})
}

export function foodRestrictionOptions() {
  return [
    "None",
    "Gluten Free",
    "Vegetarian",
    "Vegan",
    "Pescatarian",
    "Kosher",
    "Halal",
    "Low Carb",
    "Other"
  ].map(x=> {return {value: x, label: x}})
}

export function  getJacketShirtSizeOptions() {
  return [
    "Women - XS",
    "Women - SM",
    "Women - MED",
    "Women - LG",
    "Women - XL",
    "Women - 2XL",
    "Men - SM",
    "Men - MED",
    "Men - LG",
    "Men - XL",
    "Men - 2XL",
    "Men - 3XL",
  ].map(x=> {return {value: x, label: x}})
}

export function getArrivalDateOptions() {
  return [
    "May 10",
    "May 11",
    "May 12",
    "May 13",
    "May 14",
    "May 15"
  ].map(x=> {return {label:x, value: x}})
}

export function getRoomPreferenceOptions() {
  return [
    "King Bed", "Double Beds"
  ].map(x=> {return {label:x, value: x}})
}


export function getDepartureDateOptions() {
  return [
    "May 17",
    "May 18",
    "May 19",
    "May 20",
    "May 21"
  ].map(x=> {return {label:x, value: x}})
}

export function getNumberOfGuestOptions() {
  return [
    1,2
  ].map(x=> {return {label:`${x}`, value: x}})
}

export function getShoeSizeOptions() {
  const numbersWomen = [5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10,10.5,11,11.5];
  const numbersMen = [7,7.5,8,8.5,9,9.5,10,10.5,11,11.5,12,12.5,13,13.5];
  return numbersWomen.map(number => `Women ${number}`).concat(numbersMen.map(number => `Men ${number}`))
}

export function getShoeSizeOptionsObject() {
  const numbersWomen = [5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10,10.5,11,11.5];
  const numbersMen = [7,7.5,8,8.5,9,9.5,10,10.5,11,11.5,12,12.5,13,13.5];
  return numbersWomen.map(number => `Women ${number}`).concat(numbersMen.map(number => `Men ${number}`)).map(x=> {return {label: x, value: x}})
}
